import {
  SUBSCRIPTION_BUISNESS_BASIC,
  SUBSCRIPTION_BUISNESS_BASIC_TRIAL,
  SUBSCRIPTION_CREATOR,
  SUBSCRIPTION_CREATOR_TRIAL,
} from '../config';

export const CHECK_VALUE = 'check';
export const CROSS_VALUE = 'cross';

export const subscriptionPlans = [
  {
    id: SUBSCRIPTION_CREATOR,
    title: 'Creator',
    price: '€13.49*',
    amountOfUsers: 'Up to 19 Users',
    features: [
      '1 Workspace',
      'Advanced Interactive Features',
      'Unlimited Courses',
      '200,000 AI Credits included',
      '5GB Storage',
      'Limited to 1 Course Creator per workspace',
    ],
    buttonText: 'Select plan',
    green: false,
    showDetails: true,
    startsAt: '*Starts at €899',
    usersPerMonth: true,
    firstFeatureBold: false,
    trialTier: SUBSCRIPTION_CREATOR_TRIAL,
    trialText: 'Start 6 month trial',
  },
  {
    id: SUBSCRIPTION_BUISNESS_BASIC,
    title: 'Business Basic',
    price: '€11.99*',
    amountOfUsers: 'Up to 250 Users',
    features: [
      'Everything in the Creator Plan, plus',
      '400,000 AI Credits included',
      'LTI Support for seamless integration with other platforms',
      'No limit on the number of Course Creators per workspace',
      '50GB Storage',
      'White label/Customization',
    ],
    buttonText: 'Select plan',
    green: true,
    showDetails: true,
    startsAt: '*Starts at €3,778',
    usersPerMonth: true,
    firstFeatureBold: true,
    trialTier: SUBSCRIPTION_BUISNESS_BASIC_TRIAL,
    trialText: 'Start 7 day trial',
  },
  {
    title: 'Enterprise',
    price: 'On Request',
    amountOfUsers: 'Unlimited Users',
    features: [
      'Everything in the Business Plan, plus',
      '3,000,000 AI Credits included',
      '100GB Storage',
      'Ultra High Definition Content Delivery',
      'Full SCORM Support with Player Integration',
      'Custom Data Storage & Integrations',
      'Personalized Account & Onboarding Support',
    ],
    buttonText: 'Get Quote',
    green: false,
    showDetails: false,
    startsAt: null,
    usersPerMonth: false,
    firstFeatureBold: true,
    buttonSecondary: true,
  },
];

export const FeaturesTableConf = {
  header: ['', '€13.49', '€11.99', 'Contact us'],
  subheader: ['', 'Creator', 'Business Basic', 'Enterprise'],
  sections: [
    {
      title: 'Core Features',
      data: [
        ['Unlimited Course Creation', CHECK_VALUE, CHECK_VALUE, CHECK_VALUE],
        [
          'Lesson Builder (Video, Image, Audio)',
          CHECK_VALUE,
          CHECK_VALUE,
          CHECK_VALUE,
        ],
        ['Course Analytics', CHECK_VALUE, CHECK_VALUE, CHECK_VALUE],
      ],
    },
    {
      title: 'Assessable Course Tools',
      data: [
        ['Multiple Choice Questions', CHECK_VALUE, CHECK_VALUE, CHECK_VALUE],
        ['Supplemental Learning Paths', CHECK_VALUE, CHECK_VALUE, CHECK_VALUE],
        ['Challenge Branching', CHECK_VALUE, CHECK_VALUE, CHECK_VALUE],
        ['Jump-to Nodes', CROSS_VALUE, CHECK_VALUE, CHECK_VALUE],
      ],
    },
    {
      title: 'tapybl AI',
      data: [
        ['Base AI Credits', '200,000', '400,000', '3,000,000'],
        ['Option to Add AI Credits', CHECK_VALUE, CHECK_VALUE, CHECK_VALUE],
      ],
    },
    {
      title: 'Data Storage',
      data: [
        ['Data Storage Included', '5GB', '50GB', '100GB'],
        [
          'Option to Purchase Additional Storage',
          CHECK_VALUE,
          CHECK_VALUE,
          CHECK_VALUE,
        ],
      ],
    },
    {
      title: 'Video Resolution',
      data: [['Maximum Resolution Supported', 'HD', 'HD', 'UHD']],
    },
    {
      title: 'Integration',
      data: [
        ['SCORM Runtime Compatible', '*', CHECK_VALUE, CHECK_VALUE],
        ['LTI Support', CROSS_VALUE, CHECK_VALUE, CHECK_VALUE],
        [
          'Content Provider Integration (Vimeo, YouTube)',
          CROSS_VALUE,
          CROSS_VALUE,
          CHECK_VALUE,
        ],
        ['SCORM Full (including player)', '*', '*', CHECK_VALUE],
      ],
    },
    {
      title: 'Users per Package',
      data: [
        ['Total Course Authors', '1', '1', '5'],
        ['Total Admin Roles', '1', 'Unlimited', 'Unlimited'],
        ['Users in Base Package', '1', '20', '1001'],
        ['Maximum Users Allowed', '19', '250', 'UNLIMITED'],
      ],
    },
    {
      title: 'Support',
      data: [
        ['Managed Help Desk', CROSS_VALUE, CHECK_VALUE, CHECK_VALUE],
        ['Account & Onboarding Support', CROSS_VALUE, CROSS_VALUE, CHECK_VALUE],
      ],
    },
  ],
};
