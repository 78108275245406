import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import CourseInstructorUI from '../view/CourseInstructorUI';
import CourseInstructorStore from '../store/CourseInstructorStore';
import {useOutletContext, useParams} from 'react-router-dom';
import {Course, CourseInstructor} from '../../../../data/models/Course/Course';

interface Props {
  courseInstructorStore?: CourseInstructorStore;
}

const CourseInstructorContainer = inject('courseInstructorStore')(
  observer(({courseInstructorStore}: Props) => {
    const {organizationId} = useParams();
    const [instructorData, setInstructorData] = React.useState<any[]>([]);
    const [currentInstructor, setCurrentInstructor] =
      React.useState<CourseInstructor>();
    const context = useOutletContext() as [any, any, Course];

    useEffect(() => {
      fetchInstructors();
    }, [courseInstructorStore, context]);

    const fetchInstructors = () => {
      if (organizationId && context[2]) {
        courseInstructorStore!
          .getInstructorCandidates(organizationId, context[2].id || '')
          .then(res => {
            setInstructorData(res.data || []);
          });
        courseInstructorStore
          ?.getCurrentInstructor(organizationId, context[2].id || '')
          .then(setCurrentInstructor);
      }
    };

    const onSave = (selectedInstructor: string) => {
      if (organizationId && context[2]) {
        courseInstructorStore!
          .saveInstructors(
            selectedInstructor,
            organizationId,
            context[2].id || '',
          )
          .then(fetchInstructors);
      }
    };

    return (
      <CourseInstructorUI
        instructorData={instructorData}
        onSave={onSave}
        currentInstructor={currentInstructor}
        isLoading={courseInstructorStore!.isLoading}
        publishedCourse={context[2]}
      />
    );
  }),
);

export default CourseInstructorContainer;
