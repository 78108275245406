import React, {useEffect, useRef, useState} from 'react';
import {appText} from '../../../assets/text/appText';
import './styles.css';
import {uploadIcon} from '../../../assets/images';
import Lottie from 'lottie-react';
import {loadingLottie} from '../../../assets/lottie';
import {
  MEDIA_FILES_ALL,
  MEDIA_FILES_IMAGE,
  MEDIA_FILES_VIDEO,
} from '../../../data/models/LibraryFile/MediaFile';
import {toast} from 'react-toastify';
import {getErrorFromCode, internalErrorCodes} from '../../../data/errorCodes';
import {toastConfig} from '../../Toast/toastConfig';

interface Props {
  onVideoSelected?: (video: File) => void;
  isLoading?: boolean;
  type?: number;
  maxFileSize?: number;
}

const UploadVideoButton = React.memo(
  ({onVideoSelected, isLoading, type}: Props) => {
    const [file, setFile] = useState<File>();
    const inputFile = useRef<any>(null);

    useEffect(() => {
      if (file && onVideoSelected) {
        onVideoSelected(file);
        setFile(undefined);
      }
    }, [file]);

    const onUpload = () => {
      inputFile.current.value = '';
      inputFile.current?.click();
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (!file) {
        toast.error(getErrorFromCode(''), toastConfig);
        return;
      }

      if (fileIsAnImage(file)) {
        processImage(file);
        return;
      }

      if (fileIsAVideo(file)) {
        setFile(file);
        return;
      }

      if (fileIsADoc(file) && onVideoSelected) {
        onVideoSelected(file);
        return;
      }

      toast.error(
        getErrorFromCode(internalErrorCodes.INVALID_FILE_EXTENSION),
        toastConfig,
      );
    };

    const processImage = async (file: File) => {
      if (!onVideoSelected) return;
      onVideoSelected(file);
    };

    const fileIsAnImage = (file: File) => {
      return file.type.includes('image');
    };

    const fileIsADoc = (file: File) => {
      return (
        file.type.includes('pdf') ||
        file.type.includes('msword') ||
        file.type.includes('wordprocessingml.document')
      );
    };

    const fileIsAVideo = (file: File) => {
      return file.type.includes('video');
    };

    const getFileTypesByType = () => {
      switch (type) {
        case MEDIA_FILES_ALL:
          return 'image/*,video/mp4,video/x-m4v';
        case MEDIA_FILES_IMAGE:
          return 'image/*';
        case MEDIA_FILES_VIDEO:
          return 'video/mp4,video/x-m4v';
      }
    };

    return (
      <div
        className={`UploadVideoButtonContainer ${
          isLoading ? 'UploadVideoButtonContainerDisabled' : ''
        }`}>
        <button onClick={onUpload} disabled={isLoading}>
          {isLoading ? (
            <Lottie
              animationData={loadingLottie}
              loop={true}
              style={{
                width: '14px',
                height: '14px',
                marginRight: '8.5px',
              }}
            />
          ) : (
            <img src={uploadIcon} alt="upload" />
          )}
          {appText.upload}
          <input
            type="file"
            onChange={onChange}
            accept={getFileTypesByType()}
            style={{display: 'none'}}
            ref={inputFile}
          />
        </button>
      </div>
    );
  },
);

export default UploadVideoButton;
