import {makeAutoObservable, runInAction} from 'mobx';
import AuthRepository from '../../data/repositories/AuthRepository';
import OrganizationRepository from '../../data/repositories/OrganizationRepository';
import SubscriptionRepository from '../../data/repositories/SubscriptionRepository';
import {UserOrganizationDetails} from '../../data/models/Oganization/Organization';

class NavigationStore {
  private authRepo = new AuthRepository();
  private organizationRepo = new OrganizationRepository();
  private subscriptionRepository = new SubscriptionRepository();

  private storageUsed = 0;
  private usersUsed = 0;

  private userOrganizationCache?: UserOrganizationDetails;

  constructor() {
    makeAutoObservable(this);
  }

  public get userOrganization() {
    return this.userOrganizationCache?.organization;
  }

  public get userPermissions() {
    return this.userOrganizationCache?.userRolePermissions || [];
  }

  public get subscriptionStatus() {
    return this.userOrganizationCache?.subscriptionState;
  }

  public get subscriptionPermissions() {
    return this.userOrganizationCache?.permissions;
  }

  public async getOrgSubscriptionPermissions(organizationId: string) {
    const result =
      await this.subscriptionRepository.getSubscriptionPermissions(
        organizationId,
      );
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async getOrganizationHasSubscription(organizationId: string) {
    const result =
      await this.subscriptionRepository.getOrganizationHasSubscription(
        organizationId,
      );
    if (result.success && result.data) {
      return result.data;
    } else {
      return {result: false};
    }
  }

  public async getOrganizationDetails(organizationId: string) {
    const result =
      await this.organizationRepo.getOrganizationDetails(organizationId);
    if (result.success && result.data) {
      this.setStorageUsed(result.data.totalLibraryMediaFileSizesInMb);
      return result.data;
    }
  }

  public async checkCourseEditAccess(courseId: string) {
    const result = await this.organizationRepo.checkCourseEditAccess(courseId);
    if (result.success && result.data) {
      return result.data.access;
    }
    return false;
  }

  public async getOrganizationUsersCount(organizationId: string) {
    const result =
      await this.organizationRepo.getOrganizationUsersCount(organizationId);
    if (result.success && result.data) {
      this.setUsersUsed(result.data?.count || 0);
      return result.data;
    }
  }

  public getStoredUser() {
    return this.authRepo.getUser();
  }

  public async tryGetMyOrganization() {
    const result = await this.organizationRepo.getMyOrganization();
    if (result.success && result.data) {
      runInAction(() => {
        this.userOrganizationCache = result.data;
      });
      return result.data;
    }
  }

  public applyThemeColors() {
    this.organizationRepo.applyThemeColors(
      this.userOrganizationCache?.organization,
    );
  }

  public refreshToken() {
    return this.authRepo.refreshToken();
  }

  public getStorageUsed() {
    return this.storageUsed;
  }

  public getUsersUsed() {
    return this.usersUsed;
  }

  private setStorageUsed(storageUsed: number) {
    this.storageUsed = storageUsed;
  }

  private setUsersUsed(usersUsed: number) {
    this.usersUsed = usersUsed;
  }
}

export default NavigationStore;
