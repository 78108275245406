import React, {ReactNode} from 'react';
import {appText} from '../../assets/text/appText';
import './styles.css';
import {lightBulb} from '../../assets/images';

interface Props {
  tip: ReactNode;
}

const TipBox = React.memo(({tip}: Props) => {
  return (
    <div className="TipBox">
      <div className="Header">
        <img src={lightBulb} />
        <h6>{appText.tapyblTips}</h6>
      </div>
      <p>{tip}</p>
    </div>
  );
});

export default TipBox;
