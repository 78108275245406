import React, {useEffect, useState} from 'react';
import CoursePreviewUI from '../view/CoursePreviewUI';
import {inject, observer} from 'mobx-react';
import CoursePreviewStore from '../store/CoursePreviewStore';
import {CoursePresentation} from '../../../../data/models/Course/CoursePresentation';
import {useNavigate, useParams} from 'react-router-dom';
import {
  COURSE_PUBLISHED_STATUS,
  COURSE_PUBLISHING_STATUS,
} from '../../../../data/models/Course/Course';
import NavigationStore from '../../../../navigation/store/NavigationStore';
import {APP_PERMISSIONS} from '../../../../data/permissions';

interface Props {
  coursePreviewStore?: CoursePreviewStore;
  navigationStore?: NavigationStore;
}

const CoursePreviewContainer = inject(
  'coursePreviewStore',
  'navigationStore',
)(
  observer(({coursePreviewStore, navigationStore}: Props) => {
    const [coursePresentation, setCoursePresentation] =
      useState<CoursePresentation>();

    const {courseId, organizationId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      fetchPresentation();
    }, []);

    const getCourseLink = () => {
      return `/manage/${organizationId}/courses`;
    };

    const onEditFolders = () => {
      navigate(`/editor/${organizationId}/courses/${courseId}/folders`);
    };

    const onLessonClick = (lessonId: string) => {
      navigate(
        `/preview/${organizationId}/course/${courseId}/lesson/${lessonId}`,
      );
    };

    const onPublishCourse = () => {
      if (organizationId && courseId) {
        if (
          coursePresentation?.course.publishStatus === COURSE_PUBLISHING_STATUS
        ) {
          return;
        }
        if (
          coursePresentation?.course.publishStatus === COURSE_PUBLISHED_STATUS
        ) {
          coursePreviewStore
            ?.republishCourse(organizationId, courseId)
            .then(fetchPresentation);
        } else {
          coursePreviewStore
            ?.publishCourse(organizationId, courseId)
            .then(fetchPresentation);
        }
      }
    };

    const fetchPresentation = () => {
      if (courseId && organizationId) {
        coursePreviewStore!
          .getCoursePresentation(organizationId, courseId)
          .then(setCoursePresentation);
      }
    };

    return (
      <CoursePreviewUI
        course={coursePresentation?.course}
        organization={coursePresentation?.organization}
        courseLink={getCourseLink()}
        onEditFolders={onEditFolders}
        onLessonClick={onLessonClick}
        onPublishCourse={onPublishCourse}
        isLoading={coursePreviewStore!.isLoading}
        hasPublishPermission={navigationStore!.userPermissions.includes(
          APP_PERMISSIONS.ORG_CREATE_COURSES,
        )}
      />
    );
  }),
);

export default CoursePreviewContainer;
