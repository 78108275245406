import React from 'react';
import {useState, useEffect} from 'react';
import TextInput from '../../../uiToolkit/Inputs/TextInput';
import TimeInput from '../../../uiToolkit/Inputs/TimeInput/indes';
import {generateAIVideosText} from '../generateAIVideosText';
import {Col} from 'react-bootstrap';
import MultiInput from '../../../uiToolkit/Inputs/MultiInput';
import {getSecondsFromTimeText} from '../../../utils/getTimeTextFromSeconds';

interface Props {
  ignoreAudioSlidesList: string;
  ignoreAudioSlidesDuration: string;
  ignoreSlidesList: string;
  ignorePhrasesList: string[];
  setIgnoreAudioSlidesList: (value: string, isValid: boolean) => void;
  setIgnoreAudioSlidesDuration: (value: string) => void;
  setIgnoreSlidesList: (value: string, isValid: boolean) => void;
  setIgnorePhrasesList: (value: string[]) => void;
}

const MuteExcudeStep = React.memo(
  ({
    ignoreAudioSlidesDuration,
    ignoreAudioSlidesList,
    ignoreSlidesList,
    setIgnoreAudioSlidesDuration,
    setIgnoreAudioSlidesList,
    setIgnoreSlidesList,
    ignorePhrasesList,
    setIgnorePhrasesList,
  }: Props) => {
    const [showWarning, setShowWarning] = useState(false);

    useEffect(() => {
      const seconds = getSecondsFromTimeText(ignoreAudioSlidesDuration);
      if (seconds > 601) {
        setShowWarning(true);
      } else {
        setShowWarning(false);
      }
    }, [ignoreAudioSlidesDuration]);

    const slidesListIsValid = (value: string) => {
      if (value === '') {
        return true;
      } else {
        const result = new RegExp(
          /^(\d|,\s\d|\s\d-\d|,\d|,\s\d-\d|\d,|\d-\d|,\d-\d)*$/gm,
        ).test(value);
        return result;
      }
    };

    const onAudioIgnoreSlidesChange = (value: string) => {
      setIgnoreAudioSlidesList(value, slidesListIsValid(value));
    };

    const onIgnoreSlidesChange = (value: string) => {
      setIgnoreSlidesList(value, slidesListIsValid(value));
    };

    return (
      <>
        <Col lg={6} xs={12} className="formBox">
          <h5>{generateAIVideosText.slideIgnoreAudioHeadingText}</h5>
          <>
            <TextInput
              value={ignoreAudioSlidesList}
              onChange={onAudioIgnoreSlidesChange}
              placeholder={generateAIVideosText.slideIgnoreAudioPlaceholderText}
              maxCharacters={150}
              fieldError={!slidesListIsValid(ignoreAudioSlidesList)}
            />
          </>

          {ignoreAudioSlidesList && (
            <>
              <h5>{generateAIVideosText.slideIgnoreAudioDurationTitle}</h5>
              <h6>{generateAIVideosText.slideIgnoreAudioDurationSubtitle}</h6>
              <>
                <TimeInput
                  value={ignoreAudioSlidesDuration}
                  onChange={setIgnoreAudioSlidesDuration}
                  isValid
                  uiType="text"
                  className="mb-3"
                  isMandatory
                  fieldError={
                    getSecondsFromTimeText(ignoreAudioSlidesDuration) === 0 ||
                    showWarning
                  }
                  warningText={
                    showWarning
                      ? generateAIVideosText.slideIgnoreAudioDurationWarning
                      : null
                  }
                />
              </>
            </>
          )}

          <h5>{generateAIVideosText.slideIgnoreHeadingText}</h5>
          <>
            <TextInput
              value={ignoreSlidesList}
              onChange={onIgnoreSlidesChange}
              placeholder={generateAIVideosText.slideIgnorePlaceholderText}
              maxCharacters={150}
              fieldError={!slidesListIsValid(ignoreSlidesList)}
            />
          </>

          <h5>{generateAIVideosText.textIgnoreHeadingText}</h5>
          <>
            <MultiInput
              value={ignorePhrasesList}
              setValue={setIgnorePhrasesList}
              placeholder={generateAIVideosText.textIgnorePlaceholder}
              validator={() => true}
              customDelimiter=","
            />
          </>
        </Col>
      </>
    );
  },
);

export default MuteExcudeStep;
