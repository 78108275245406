function getTextWidth(text: string, fontSize: number) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (context) {
    context.font = `${fontSize}px 'Walsheim', sans-serif`;
    return context.measureText(text).width;
  } else {
    return 0;
  }
}

function breakString(
  word: string,
  maxWidth: number,
  fontSize: number,
  hyphenCharacter = '-',
) {
  const characters = word.split('');
  const lines: string[] = [];
  let currentLine = '';
  characters.forEach((character, index) => {
    const nextLine = `${currentLine}${character}`;
    const lineWidth = getTextWidth(nextLine, fontSize);
    if (lineWidth >= maxWidth) {
      const currentCharacter = index + 1;
      const isLastLine = characters.length === currentCharacter;
      const hyphenatedNextLine = `${nextLine}${hyphenCharacter}`;
      lines.push(isLastLine ? nextLine : hyphenatedNextLine);
      currentLine = '';
    } else {
      currentLine = nextLine;
    }
  });
  return {hyphenatedStrings: lines, remainingWord: currentLine};
}

function wrapLabel(label: string, maxWidth: number, fontSize: number) {
  const words = label.split(' ');
  const completedLines: string[] = [];
  let nextLine = '';
  words.forEach((word, index) => {
    const wordLength = getTextWidth(`${word} `, fontSize);
    const nextLineLength = getTextWidth(nextLine, fontSize);
    if (wordLength > maxWidth) {
      const {hyphenatedStrings, remainingWord} = breakString(
        word,
        maxWidth,
        fontSize,
      );
      completedLines.push(nextLine, ...hyphenatedStrings);
      nextLine = remainingWord;
    } else if (nextLineLength + wordLength >= maxWidth) {
      completedLines.push(nextLine);
      nextLine = word;
    } else {
      nextLine = [nextLine, word].filter(Boolean).join(' ');
    }
    const currentWord = index + 1;
    const isLastWord = currentWord === words.length;
    if (isLastWord) {
      completedLines.push(nextLine);
    }
  });
  return completedLines.filter(line => line !== '');
}

export {wrapLabel};
