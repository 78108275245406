export const subscriptionFirstPaymentText = {
  goBack: 'Go back to tier selection',
  title: 'Billing and Subscription Details',
  subtitle:
    "Thank you for choosing tapybl! You're just a step away from completing your order.",
  point1: (price: string) =>
    `Your subscription will auto-renew at ${price}/year.`,
  point2:
    'You can cancel anytime before the next billing cycle to avoid further charges.',
  point3: 'By completing your purchase, you agree to our ',
  terms: 'Terms of Service and Privacy Policy.',
  info1: 'Please review your order details below and proceed to payment.',
  info2:
    'If you have any questions or encounter any issues, feel free to reach out to our ',
  info3:
    'Your payment information is processed securely using Stripe. We do not store or share your card details.',
  support: 'support team.',
  summary: 'Summary',
  selectedTier: 'Selected Tier',
  planDescription: 'Plan Description',
  price: 'Price',
  cancel: 'Cancel',
  proceed: 'Proceed to payment',
};
