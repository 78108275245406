import {makeAutoObservable, runInAction} from 'mobx';
import CoursesRepository from '../../../../data/repositories/CoursesRepository';
import {CourseLegendItem} from '../../../../data/models/UI/ActiveTabs';
import LoadingModule from '../../../LoadingModule/LoadingModule';

export const COURSE_LEGEND_PAGE_SIZE = 20;

class SidebarNavigationStore {
  private courseRepo = new CoursesRepository();

  private loadingModule = new LoadingModule();
  private _courseLegend: CourseLegendItem[] = [];
  private _pagesCount = 0;
  private _currentPage = 0;

  constructor() {
    makeAutoObservable(this);
  }

  public get courseLegend() {
    return this._courseLegend;
  }

  public get pagesCount() {
    return this._pagesCount;
  }

  public get currentPage() {
    return this._currentPage;
  }

  public setCurrentPage(page: number) {
    this._currentPage = page;
  }

  public async fetchInitialPosition(organizationId: string, courseId: string) {
    this.loadingModule.startLoading();
    const result = await this.courseRepo.getSidebarCoursePosition(
      organizationId,
      courseId,
      COURSE_LEGEND_PAGE_SIZE,
    );
    runInAction(() => {
      if (result.success) {
        this._currentPage = result.data;
      }
    });
    await this.getSidebarOptions(organizationId, '');
  }

  public async getSidebarOptions(organizationId: string, search: string) {
    this.loadingModule.startLoading();
    const result = await this.courseRepo.getSidebarOptions(
      organizationId,
      this._currentPage,
      COURSE_LEGEND_PAGE_SIZE,
      search,
    );
    this.loadingModule.endLoading();
    runInAction(() => {
      if (result.success && result.data) {
        this._courseLegend = result.data.data;
        this._pagesCount = result.data.pagesCount;
      }
    });
  }
}

export default SidebarNavigationStore;
