import React, {useEffect} from 'react';
import {Col, Row} from 'react-bootstrap';
import {courseEditText} from '../../courseEditText';
import {CourseEdit} from '../../../../data/models/Course/CourseEdit';
import TextInput from '../../../../uiToolkit/Inputs/TextInput';
import ImagePickerInput from '../../../../uiToolkit/Inputs/ImagePickerInput';
import TextAreaInput from '../../../../uiToolkit/Inputs/TextAreaInput';
import Button from '../../../../uiToolkit/Buttons/Button';
import {
  Course,
  COURSE_PUBLISHED_STATUS,
} from '../../../../data/models/Course/Course';
import './styles.css';

interface Props {
  course: Course;
  onSave: (CourseEdit: CourseEdit) => void;
  isLoading: boolean;
}

const GeneralCourseEditUI = React.memo(({course, onSave, isLoading}: Props) => {
  const [courseEdit, setCourseEdit] = React.useState<CourseEdit>();

  useEffect(() => {
    if (course) {
      setCourseEdit({
        ...courseEdit,
        title: course.title,
        description: course.description,
      });
    }
  }, [course]);

  const onCancel = () => {
    setCourseEdit({
      ...courseEdit,
      title: course.title,
      description: course.description,
      image: undefined,
    });
  };

  const onChangeImage = async (file?: File) => {
    if (file) {
      setCourseEdit({
        ...courseEdit,
        image: file,
      });
    } else {
      setCourseEdit({
        ...courseEdit,
        image: undefined,
      });
    }
  };

  const changesWasMade = () => {
    if (courseEdit) {
      return (
        courseEdit.description !== course.description ||
        courseEdit.image ||
        courseEdit.title !== course.title
      );
    }
    return false;
  };

  const dataIsValid = () => {
    if (courseEdit) {
      return (
        courseEdit.description?.trim() !== '' && courseEdit.title?.trim() !== ''
      );
    }
  };

  const onInitiateSave = () => {
    if (courseEdit && changesWasMade() && dataIsValid()) {
      onSave(courseEdit);
    }
  };

  return (
    <div className="GeneralCourseEditContainer">
      <Row className="CourseEditRow">
        <Col lg={5} xs={12}>
          <TextInput
            type="text"
            value={courseEdit?.title || ''}
            onChange={(value: string) =>
              setCourseEdit({...courseEdit, title: value})
            }
            placeholder={course.title}
            maxCharacters={100}
            heading={courseEditText.GeneralName}
            className="CreateCourseInput"
            isMandatory
          />
        </Col>
        <Col lg={{span: 5, offset: 2}} xs={12}>
          <ImagePickerInput
            image={courseEdit?.image}
            onChange={onChangeImage}
            existingImage={course?.image}
            heading={courseEditText.PosterHeading}
            placeholder={courseEditText.imagePlaceholder}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <TextAreaInput
            value={courseEdit?.description || ''}
            onChange={(value: string) =>
              setCourseEdit({...courseEdit, description: value})
            }
            placeholder={courseEditText.DescriptionPlaceholder}
            heading={courseEditText.GeneralDescription}
            className="CreateCourseInput"
            isMandatory
          />
        </Col>
      </Row>
      <Row>
        <Col lg={{span: 5}} xs={12}>
          <div className="Footer">
            <Button
              title={courseEditText.GeneralCancelButton}
              onClick={onCancel}
              uiType="text"
            />
            <Button
              title={courseEditText.GeneralSaveButton}
              onClick={onInitiateSave}
              uiType="action"
              disabled={!changesWasMade() || !dataIsValid()}
              isLoading={isLoading}
              tooltip={
                course.publishStatus === COURSE_PUBLISHED_STATUS
                  ? courseEditText.republishTooltip
                  : undefined
              }
            />
          </div>
        </Col>
      </Row>
    </div>
  );
});

export default GeneralCourseEditUI;
