import React from 'react';
import DataTable from 'react-data-table-component';
import TablePagination from '../../../../uiToolkit/TablePagination';
import {getFirstCapitals} from '../../../../utils/getFirstCapitals';
import {courseInstructorText} from '../courseInstructorText';
import Checkbox from '../../../../uiToolkit/Inputs/Checkbox';
import Button from '../../../../uiToolkit/Buttons/Button';
import {CourseInstructor} from '../../../../data/models/Course/Course';
import '../../../../uiToolkit/DataTable/styles.css';
import './styles.css';
import CourseNotPublished from '../../components/CourseNotPublished';

interface Props {
  instructorData: any[];
  onSave: (selectedInstructors: string) => void;
  currentInstructor?: CourseInstructor;
  isLoading: boolean;
  publishedCourse?: any;
}

const columns = (
  checkedInstructorId: string,
  setCheckedInstructorId: React.Dispatch<React.SetStateAction<string>>,
) => [
  {
    name: '',
    selector: (row: any) => row.staffMemberId,
    cell: (row: any) => (
      <Checkbox
        checked={checkedInstructorId === row.staffMemberId}
        className="Checkbox"
        onCheck={() =>
          handleCheckboxChange(
            row.staffMemberId,
            checkedInstructorId,
            setCheckedInstructorId,
          )
        }
        radio
      />
    ),
    id: 'InstructorCheckboxColumn',
  },
  {
    name: 'Name',
    selector: (row: any) => row.name,
    cell: (row: any) => (
      <div className="InstructorNameColumn">
        {row.avatarUrl ? (
          <img
            src={row.avatarUrl}
            alt="User Profile Picture"
            className="InstructorModalColumn"
          />
        ) : (
          <span className="Initials">{getFirstCapitals(row.name || '')}</span>
        )}
        <p className="DataTableText">{row.name}</p>
      </div>
    ),
    grow: 7,
    id: 'NameColumn',
  },
  {
    name: 'Email',
    selector: (row: any) => row.email,
    cell: (row: any) => <p className="DataTableText">{row.email}</p>,
    grow: 6,
    id: 'EmailColumn',
  },
  {
    name: 'Role',
    selector: (row: any) => row.roleName,
    cell: (row: any) => <p className="DataTableText">{row.roleName}</p>,
    grow: 6,
    id: 'RoleColumn',
  },
];

const handleCheckboxChange = (
  instructorId: string,
  checkedInstructorId: string,
  setCheckedInstructorId: React.Dispatch<React.SetStateAction<string>>,
) => {
  if (instructorId === checkedInstructorId) {
    setCheckedInstructorId('');
  } else {
    setCheckedInstructorId(instructorId);
  }
};

const CourseIntructorUI = React.memo(
  ({
    onSave,
    instructorData,
    currentInstructor,
    isLoading,
    publishedCourse,
  }: Props) => {
    const [checkedInstructorId, setCheckedInstructorId] = React.useState('');
    return (
      <div className="CourseEditContainer">
        {publishedCourse ? (
          <div className="AddInstructorWizardContainer">
            {currentInstructor && (
              <div className="CurrentInstructorContainer">
                <span className="SectionTitle">
                  {courseInstructorText.currentInstructor}
                </span>
                <div className="d-flex flex-row">
                  <div className="AvatarContainer">
                    {/* <img src={currentInstructor.instructor.userName} /> */}
                    <span>
                      {getFirstCapitals(currentInstructor.instructor.userName)}
                    </span>
                  </div>
                  <div className="CurrentInftructorInfo">
                    <p>{currentInstructor.instructor.userName}</p>
                    <span>{currentInstructor.instructor.email}</span>
                  </div>
                </div>
              </div>
            )}
            <span className="SectionTitle">
              {courseInstructorText.eligibleInstructors}
            </span>
            <div className="dataTableContainer">
              <DataTable
                data={instructorData}
                columns={columns(checkedInstructorId, setCheckedInstructorId)}
                className="AddInstructorTable"
                pagination
                striped
                theme="custom-stripes"
                paginationPerPage={10}
                paginationComponent={TablePagination}
              />
            </div>
            <Button
              title={courseInstructorText.assignInstructor}
              onClick={() => onSave(checkedInstructorId)}
              uiType="action"
              disabled={!checkedInstructorId}
              isLoading={isLoading}
              className="SaveButton"
            />
          </div>
        ) : (
          <CourseNotPublished subtitle={courseInstructorText.notPublished} />
        )}
      </div>
    );
  },
);

export default CourseIntructorUI;
