import {inject, observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import AIFilesStore from '../store/AiFilesStore';
import AIFilesUI from '../view/AiFilesUI';
import {
  MediaFile,
  VideoTranscriptSegment,
} from '../../../../data/models/LibraryFile/MediaFile';
import {useNavigate, useParams} from 'react-router-dom';
import NavigationStore from '../../../../navigation/store/NavigationStore';
import ConfirmationModal from '../../../../uiToolkit/ConfirmationModal';
import FilePreviewContainer from '../../Root/container/FilePreviewContainer';
import {videoSourcesText} from '../../videoSourcesText';
import VideoSourcesStore from '../../Root/store/VideoSourcesStore';
import DownloadTranscriptionModal from '../../Root/components/DownloadTranscriptionModal';

interface Props {
  aiFilesStore?: AIFilesStore;
  navigationStore?: NavigationStore;
  videoSourcesStore?: VideoSourcesStore;
}

const AIFilesContainer = inject(
  'aiFilesStore',
  'navigationStore',
  'videoSourcesStore',
)(
  observer(({aiFilesStore, navigationStore, videoSourcesStore}: Props) => {
    const [fileToPreview, setFileToPreview] = useState<MediaFile>();
    const [fileToDeleteId, setFileToDeleteId] = useState<string>();
    const [fileTranscriptions, setFileTranscriptions] =
      useState<VideoTranscriptSegment[]>();

    const {organizationId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      if (organizationId) {
        aiFilesStore!.setCurrentPage(0);
        aiFilesStore!.setSortOptions('file_name', 'desc');
        aiFilesStore!.fetchFiles(organizationId);
      }
    }, []);

    const onPageChange = (page: number) => {
      aiFilesStore!.setCurrentPage(page - 1);
      if (organizationId) {
        aiFilesStore!.fetchFiles(organizationId);
      }
    };

    const onSort = (selectedColumn: any, sortDirection: any) => {
      aiFilesStore!.setSortOptions(selectedColumn.sortField, sortDirection);
      if (organizationId) {
        aiFilesStore!.fetchFiles(organizationId);
      }
    };

    const refetchFiles = () => {
      if (organizationId) {
        aiFilesStore!.fetchFiles(organizationId);
        navigationStore!.getOrganizationDetails(organizationId);
      }
    };

    const onDelete = () => {
      if (organizationId && fileToDeleteId) {
        videoSourcesStore!
          .deleteMediaFile(organizationId, fileToDeleteId)
          .then(refetchFiles);
        setFileToDeleteId(undefined);
      }
    };

    const onEditVideo = (file: MediaFile) => {
      navigate(`/manage/${organizationId}/editVideo/${file.id}`);
    };

    const onDownloadTranscript = async (id: string) => {
      if (organizationId) {
        const result = await videoSourcesStore!.downloadTranscript(
          organizationId,
          id,
        );
        if (result.length) {
          setFileTranscriptions(result);
        }
      }
    };

    return (
      <>
        <AIFilesUI
          files={aiFilesStore!.tapyblFiles}
          pages={aiFilesStore!.amountOfPages}
          onPreview={setFileToPreview}
          onPageChange={onPageChange}
          onDelete={setFileToDeleteId}
          onSort={onSort}
          permissions={navigationStore!.subscriptionPermissions}
          onEditVideo={onEditVideo}
          onDownloadTranscript={onDownloadTranscript}
        />
        <ConfirmationModal
          shown={fileToDeleteId !== undefined}
          onAccept={onDelete}
          onCancel={() => setFileToDeleteId(undefined)}
          acceptText={videoSourcesText.delete}
          title={videoSourcesText.confirmDelete}
        />
        <FilePreviewContainer
          fileToPreview={fileToPreview}
          onClose={() => setFileToPreview(undefined)}
        />
        {fileTranscriptions && (
          <DownloadTranscriptionModal
            transcriptions={fileTranscriptions}
            onClose={() => setFileTranscriptions(undefined)}
          />
        )}
      </>
    );
  }),
);

export default AIFilesContainer;
