import React, {useEffect, useState} from 'react';
import StaffListUI from '../view/StaffListUI';
import {useNavigate, useParams} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import {StaffRecord} from '../../../../data/models/Oganization/StaffRecord';
import ConfirmationModal from '../../../../uiToolkit/ConfirmationModal';
import {settingsText} from '../../settingsText';
import OrganizationStaffStore from '../../store/OrganizationStaffStore';
import {toast} from 'react-toastify';
import {appText} from '../../../../assets/text/appText';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import NavigationStore from '../../../../navigation/store/NavigationStore';

interface Props {
  organizationStaffStore?: OrganizationStaffStore;
  navigationStore?: NavigationStore;
}

const StaffListContainer = inject(
  'organizationStaffStore',
  'navigationStore',
)(
  observer(({organizationStaffStore, navigationStore}: Props) => {
    const [members, setMembers] = useState<StaffRecord[]>([]);
    const [memberToDeleteId, setMemeberToDeleteId] = useState<string>();
    const [dataLoaded, setDataLoaded] = useState(false);
    const {organizationId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      fetchMembers();
    }, []);

    const onInvite = () => {
      navigate(`/manage/${organizationId}/settings/invite_users`);
    };

    const onDelete = (memberId: string) => {
      setMemeberToDeleteId(memberId);
    };

    const deleteMember = async () => {
      if (
        organizationId &&
        !organizationStaffStore!.isLoading &&
        memberToDeleteId
      ) {
        organizationStaffStore!
          .deleteOrgMember(organizationId, memberToDeleteId)
          .then(() => {
            fetchMembers();
            navigationStore!.getOrganizationUsersCount(organizationId);
          });
        setMemeberToDeleteId(undefined);
      }
    };

    const activateMemeber = (memberId: string) => {
      if (organizationId && !organizationStaffStore!.isLoading) {
        organizationStaffStore!
          .activateOrgMember(organizationId, memberId)
          .then(fetchMembers);
      }
    };

    const fetchMembers = () => {
      if (organizationId) {
        organizationStaffStore!
          .getOrgStaffRecords(organizationId)
          .then(result => {
            setMembers(result);
            setDataLoaded(true);
          });
      }
    };

    const onDeleteInvitation = (invitationId: string) => {
      if (organizationId && !organizationStaffStore!.isLoading) {
        organizationStaffStore!
          .deleteInvitation(organizationId, invitationId)
          .then(() => {
            fetchMembers();
            navigationStore!.getOrganizationUsersCount(organizationId);
          });
      }
    };

    const onEdit = (memeberId: string) => {
      navigate(`/manage/${organizationId}/settings/edit_user/${memeberId}`);
    };

    const onCopyEmail = async (email: string) => {
      await navigator.clipboard.writeText(email);
      toast.success(appText.copiedToClipboard, toastConfig);
    };

    return (
      <>
        <StaffListUI
          staffMembers={members}
          onInvite={onInvite}
          onDelete={onDelete}
          onEdit={onEdit}
          onCopyEmail={onCopyEmail}
          onDeleteInvitation={onDeleteInvitation}
          activateMemeber={activateMemeber}
          dataLoaded={dataLoaded}
        />
        <ConfirmationModal
          title={settingsText.youSureDeleteMember}
          onAccept={deleteMember}
          onCancel={() => setMemeberToDeleteId(undefined)}
          shown={memberToDeleteId !== undefined}
        />
      </>
    );
  }),
);

export default StaffListContainer;
