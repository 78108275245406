import {
  JUMP_TO_SUBSCRIPTION_FEATURE,
  organizationHasFeature,
} from '../Subscription/SubscriptionPermission';
import {Point, VideoOverlay} from './ContentFreeform';

export interface LessonContentBase {
  internalId: string;
  ivNodeType: number;
}

export interface LessonContentVideo extends LessonContentBase {
  startInSeconds: number;
  endInSeconds: number;
  durationInseconds: number;
  contentId: string;
  contentUrl?: string;
  placeholderUrl?: string;
  title: string;
}

export interface LessonContentOverlayVideo extends LessonContentBase {
  startInSeconds: number;
  endInSeconds: number;
  durationInseconds: number;
  contentId: string;
  contentUrl?: string;
  placeholderUrl?: string;
  title: string;
  overlays: VideoOverlay[];
}

export interface LessonContentVimeoVideo extends LessonContentBase {
  startInSeconds: number;
  endInSeconds: number;
  durationInSeconds: number;
  contentUrl?: string;
  placeholderUrl?: string;
  title: string;
}

export interface LessonContentVimeoOverlayVideo extends LessonContentBase {
  startInSeconds: number;
  endInSeconds: number;
  durationInSeconds: number;
  contentUrl: string;
  placeholderUrl: string;
  title: string;
  overlays: VideoOverlay[];
}

export interface LessonContentChoiceQuestion extends LessonContentBase {
  title: string;
}

export interface LessonContentChoiceTextOption extends LessonContentBase {
  title: string;
}

export interface LessonContentChoiceImageOption extends LessonContentBase {
  contentId: string;
  contentUrl?: string;
}

export interface LessonContentChoiceHeader extends LessonContentBase {
  title: string;
}

export interface LessonContentMChoice extends LessonContentBase {
  question: string;
  answers: LessonContentMChoiceAnswer[];
  gradeWeight: number;
}

export interface LessonContentMChoiceAnswer {
  value: string;
  isCorrect: boolean;
}

export type LessonContentChallengeChoiceHeader = LessonContentBase;

export interface LessonContentChallengeBranchQuestion
  extends LessonContentBase {
  question: string;
  gradeWeight: number;
}

export interface LessonContentChallengeBranchImageOption
  extends LessonContentBase {
  contentId: string;
  contentUrl?: string;
  isCorrect: boolean;
}

export interface LessonContentChallengeBranchTextOption
  extends LessonContentBase {
  answer: string;
  isCorrect: boolean;
}

export interface LessonContentChallengeBranchAI extends LessonContentBase {
  answerMask: string;
  question: string;
  gradeWeight: number;
  frameMask: string;
}

export interface LessonContentFreeformImageChoice extends LessonContentBase {
  contentId: string;
  contentUrl?: string;
  x: number;
  y: number;
  width: number;
  aspect: number;
  title: string;
  encodedAtWidth: number;
  isCorrect: boolean | null;
}

export interface LessonContentFreeformTextChoice extends LessonContentBase {
  text: string;
  fontSize: number;
  x: number;
  y: number;
  color: string;
  encodedAtWidth: number;
  isCorrect: boolean | null;
}

export interface LessonContentFreeformShapeChoice extends LessonContentBase {
  overlayCoordinates: Point[];
  title: string;
  overlayVisible: boolean;
  encodedAtWidth: number;
  isCorrect: boolean | null;
}

export interface LessonContentJumpTo extends LessonContentBase {
  jumpToNodeId: string;
}

export interface LessonContentVideoBackgorund extends LessonContentBase {
  isCorrect: boolean | null;
}

export interface LessonContentAcknowledgment extends LessonContentBase {
  title: string;
  buttonTitle: string;
}

export interface LessonContentImageSource extends LessonContentBase {
  durationInSeconds: number;
  contentId: string;
  contentUrl?: string;
  title: string;
}

export type LessonContentChallengeBranchAICorrect = LessonContentBase;
export type LessonContentChallengeBranchAIIncorrect = LessonContentBase;

export const MAX_IMAGE_DURATION_SECONDS = 180;

export const VIDEO_NODE_TYPE = 0;
export const IMAGE_NODE_TYPE = 1;
export const CHOICE_HEADER_NODE_TYPE = 2;
export const CHOICE_TEXT_QUESTION = 3;
export const CHOICE_IMAGE_OPTION = 4;
export const CHOICE_TEXT_OPTION = 5;
export const MULTIPLE_CHOICE = 6;
export const CHALLENGE_BRANCH_HEADER = 7;
export const CHALLENGE_BRANCH_QUESTION = 8;
export const CHALLENGE_BRANCH_IMAGE_OPTION = 9;
export const CHALLENGE_BRANCH_TEXT_OPTION = 10;
export const CHALLENGE_BRANCH_AI = 11;
export const CHALLENGE_BRANCH_AI_CORRECT = 12;
export const CHALLENGE_BRANCH_AI_INCORRECT = 13;
export const VIMEO_VIDEO_TYPE = 14;
export const OVERLAY_VIDEO_TYPE = 15;
export const OVERLAY_VIMEO_VIDEO_TYPE = 16;

export const BRANCH_SHAPE_FREEFORM = 21;
export const BRANCH_IMAGE_FREEFORM = 22;
export const BRANCH_TEXT_FREEFORM = 23;
export const JUMP_TO_NODE = 24;
export const AUDIO_NODE = 25;
export const BRANCH_BACKGROUND_FREEFORM = 26;
export const ACKNOWLEDGMENT_NODE = 27;

export const getNextStepsForNodeByType = (
  type?: number,
  isGradable?: boolean,
  hasParent?: boolean,
  permissions?: any,
) => {
  let result = [];
  switch (type) {
    case CHOICE_HEADER_NODE_TYPE:
      result = [CHOICE_TEXT_QUESTION];
      break;
    case CHOICE_TEXT_QUESTION:
      result = [
        BRANCH_IMAGE_FREEFORM,
        BRANCH_TEXT_FREEFORM,
        BRANCH_SHAPE_FREEFORM,
        BRANCH_BACKGROUND_FREEFORM,
      ];
      break;
    case CHALLENGE_BRANCH_HEADER:
      result = [CHALLENGE_BRANCH_QUESTION];
      break;
    case CHALLENGE_BRANCH_QUESTION:
      result = [
        BRANCH_IMAGE_FREEFORM,
        BRANCH_TEXT_FREEFORM,
        BRANCH_SHAPE_FREEFORM,
        BRANCH_BACKGROUND_FREEFORM,
      ];
      break;
    default: {
      if (hasParent) {
        if (isGradable) {
          result = [
            VIDEO_NODE_TYPE,
            OVERLAY_VIDEO_TYPE,
            MULTIPLE_CHOICE,
            CHOICE_HEADER_NODE_TYPE,
            CHALLENGE_BRANCH_HEADER,
            JUMP_TO_NODE,
            ACKNOWLEDGMENT_NODE,
            IMAGE_NODE_TYPE,
          ];
        } else {
          result = [
            VIDEO_NODE_TYPE,
            OVERLAY_VIDEO_TYPE,
            CHOICE_HEADER_NODE_TYPE,
            JUMP_TO_NODE,
            ACKNOWLEDGMENT_NODE,
            IMAGE_NODE_TYPE,
          ];
        }
      } else {
        result = [VIDEO_NODE_TYPE, OVERLAY_VIDEO_TYPE, IMAGE_NODE_TYPE];
      }
    }
  }
  if (
    permissions &&
    !organizationHasFeature(permissions, JUMP_TO_SUBSCRIPTION_FEATURE) &&
    result.includes(JUMP_TO_NODE)
  ) {
    const jumpIndex = result.indexOf(JUMP_TO_NODE);
    result.splice(jumpIndex, 1);
  }
  return result;
};

export const getBaseNodes = (isGradable: boolean, hasParent: boolean) => {
  if (hasParent) {
    if (isGradable) {
      return [
        VIDEO_NODE_TYPE,
        OVERLAY_VIDEO_TYPE,
        MULTIPLE_CHOICE,
        JUMP_TO_NODE,
        ACKNOWLEDGMENT_NODE,
        IMAGE_NODE_TYPE,
      ];
    } else {
      return [
        VIDEO_NODE_TYPE,
        OVERLAY_VIDEO_TYPE,
        JUMP_TO_NODE,
        ACKNOWLEDGMENT_NODE,
        IMAGE_NODE_TYPE,
      ];
    }
  } else {
    return [VIDEO_NODE_TYPE, OVERLAY_VIDEO_TYPE, IMAGE_NODE_TYPE];
  }
};

export const nodeIsASourceNode = (type?: number) => {
  return (
    type === VIDEO_NODE_TYPE ||
    type === OVERLAY_VIDEO_TYPE ||
    type === OVERLAY_VIMEO_VIDEO_TYPE ||
    type === VIMEO_VIDEO_TYPE ||
    type === IMAGE_NODE_TYPE
  );
};
