import React from 'react';
import {
  CHECK_VALUE,
  CROSS_VALUE,
  FeaturesTableConf,
} from '../subscriptionPlans';
import '../view/styles.css';

const FeaturesTable = React.memo(() => {
  return (
    <table className="FeaturesTable">
      <thead>
        <tr className="PricesHeader">
          {FeaturesTableConf.header.map((item, index) => (
            <td key={index}>{item}</td>
          ))}
        </tr>
        <tr className="PricesSubheader">
          {FeaturesTableConf.subheader.map((item, index) => (
            <td key={index} className={index === 0 ? 'FeatureName' : ''}>
              {item}
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {FeaturesTableConf.sections.map((section, index) => (
          <>
            <tr className="SectionHeader" key={index}>
              <td className="FeatureName">{section.title}</td>
            </tr>
            {section.data.map((data, index) => (
              <tr key={index} className="FeatureListing">
                {data.map((item, index) => (
                  <td key={index} className={index === 0 ? 'FeatureName' : ''}>
                    {item === CHECK_VALUE ? (
                      <span className="icon-typeCheck"></span>
                    ) : item === CROSS_VALUE ? (
                      <div className="CrossValue">
                        <span className="icon-typePlus-icon"></span>
                      </div>
                    ) : (
                      item
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </>
        ))}
      </tbody>
    </table>
  );
});

export default FeaturesTable;
