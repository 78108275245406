import React, {useState, useRef} from 'react';
import {appText} from '../../../assets/text/appText';
import './styles.css';
import {toast} from 'react-toastify';
import {getErrorFromCode, serverErrorCodes} from '../../../data/errorCodes';
import {toastConfig} from '../../Toast/toastConfig';
import Button from '../../Buttons/Button';

interface Props {
  title?: string;
  subtitle?: string;
  onPPTSelected?: (ppt: File) => void;
  isLoading?: boolean;
  maxFileSize?: number;
  isMandatory?: boolean;
}

const UploadPPTButton = React.memo(
  ({
    title,
    subtitle,
    onPPTSelected,
    isLoading,
    maxFileSize,
    isMandatory,
  }: Props) => {
    const [fileName, setFileName] = useState<string | null>(null);
    const inputFile = useRef<any>(null);

    const onUpload = () => {
      inputFile.current.value = '';
      inputFile.current?.click();
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
        const size = file.size / 1024 / 1024;
        const maxSize = maxFileSize ? maxFileSize : 100;
        if (size > maxSize) {
          toast.error(getErrorFromCode('file_too_large'), toastConfig);
        } else {
          const ext = file.name.split('.').pop();
          if (ext !== 'pptx' && ext !== 'pdf' && ext !== 'docx') {
            toast.error(
              getErrorFromCode(serverErrorCodes.AI_CANT_READ_FILE),
              toastConfig,
            );
            return;
          }
          setFileName(file.name);
          if (onPPTSelected) {
            onPPTSelected(file);
          }
        }
      }
    };

    return (
      <div className="PPTContainer">
        {title && (
          <p className="Title">
            {title}{' '}
            {isMandatory ? <span className="MandatoryIndicator">*</span> : ''}
          </p>
        )}
        {subtitle && <p className="SubtitleText">{subtitle}</p>}
        <div className="UploadPPTButtonContainer">
          <div>
            <input
              type="file"
              onChange={onChange}
              accept=".pptx,.pdf,.docx"
              style={{display: 'none'}}
              ref={inputFile}
            />
            {fileName ? (
              <p className="UploadedFileTitle">{fileName}</p>
            ) : (
              <p className="Placeholder">{appText.noFileUploaded}</p>
            )}
          </div>
          <Button
            title={appText.browse}
            onClick={onUpload}
            disabled={isLoading}
            isLoading={isLoading}
            uiType="action"
          />
        </div>
      </div>
    );
  },
);

export default UploadPPTButton;
