import {getLocalizationBasedOnLang} from '../../data/models/User/UserInfo';

const english = {
  CourseEditRootCourse: 'Course',
  GeneralName: 'Course Name',
  GeneralDescription: 'Course Description',
  GeneralSaveButton: 'Save',
  GeneralCancelButton: 'Cancel',
  PosterHeading: 'Course Poster',
  DescriptionPlaceholder: 'Enter course description here',
  changesSaved: 'Changes Saved',
  imagePlaceholder: 'Select Image',
  republishTooltip: 'Republish the course to make changes go live.',
  courseNotPublished: 'This course is not published',
  publishCourse: 'Publish Course',
};

const welsh = {
  CourseEditRootCourse: 'Cwrs',
  GeneralName: "Enw'r Cwrs",
  GeneralDescription: "Disgrifiad o'r Cwrs",
  GeneralSaveButton: 'Cadw',
  GeneralCancelButton: 'Canslo',
  PosterHeading: 'Poster y  Cwrs',
  DescriptionPlaceholder: 'Nodwch ddisgrifiad y cwrs yma',
  changesSaved: "Newidiadau Wedi'u Cadw",
  imagePlaceholder: 'Dewis Delwedd',
  republishTooltip: "Ailgyhoeddi'r cwrs i wneud i'r newidiadau fynd yn fyw",
  courseNotPublished: "Nid yw'r cwrs wedi'i gyhoeddi",
  publishCourse: "Cyhoeddi'r Cwrs",
};

export const courseEditText: any = getLocalizationBasedOnLang(english, welsh);
