import {intervalToDuration} from 'date-fns';

export const getTimeTextFromSeconds = (seconds: number) => {
  const duration = intervalToDuration({
    start: 0,
    end: Math.ceil(seconds) * 1000,
  });
  const zeroPad = (num: any) => String(num).padStart(2, '0');
  return [duration.hours, duration.minutes, duration.seconds]
    .map(zeroPad)
    .join(':');
};

export const getTimeMilisecondsTextFromSeconds = (timeInSeconds: number) => {
  if (timeInSeconds <= 0) {
    return '00:00:00.000';
  }
  const duration = timeInSeconds * 1000;
  const milliseconds = timeInSeconds.toString().split('.')[1]
    ? timeInSeconds.toString().split('.')[1]
    : '000';
  const seconds = Math.floor((duration / 1000) % 60);
  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  const hoursStr = hours < 10 ? '0' + hours : hours;
  const minutesStr = minutes < 10 ? '0' + minutes : minutes;
  const secondsStr = seconds < 10 ? '0' + seconds : seconds;
  const milisecondsStr =
    milliseconds < '0.1'
      ? milliseconds + '00'
      : milliseconds < '0.01'
      ? milliseconds + '0'
      : milliseconds;

  return (
    hoursStr +
    ':' +
    minutesStr +
    ':' +
    secondsStr +
    '.' +
    milisecondsStr.substring(0, 3)
  );
};

export const getSecondsFromTimeText = (time: string) => {
  const timeParts = time.split(':');
  if (timeParts.length === 3) {
    return (
      parseInt(timeParts[0], 10) * 60 * 60 +
      parseInt(timeParts[1]) * 60 +
      parseInt(timeParts[2])
    );
  }
  return parseInt(timeParts[0], 10) * 60 + parseInt(timeParts[1]);
};

export const getMilisecondsFromTimeText = (time: string) => {
  const milisecondsStart = time.indexOf('.');
  const timeWithoutMiliseconds = time.slice(0, milisecondsStart);
  const milisecondsString =
    '0.' + time.slice(milisecondsStart + 1, time.length).slice(0, 3);
  const timeSeconds = getSecondsFromTimeText(timeWithoutMiliseconds);
  return timeSeconds + parseFloat(milisecondsString);
};

export const getMinutesFromSeconds = (seconds: number) => {
  return Math.ceil(seconds / 60);
};

export const truncateDurationTo3Decimals = (duration: number) => {
  const number = duration * 1000;
  return Math[number < 0 ? 'ceil' : 'floor'](number) / 1000;
};
