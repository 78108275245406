import {
  Point,
  SvgImage,
} from '../../../data/models/LessonContent/ContentFreeform';
import {
  CHALLENGE_BRANCH_QUESTION,
  IMAGE_NODE_TYPE,
  LessonContentBase,
  OVERLAY_VIDEO_TYPE,
  OVERLAY_VIMEO_VIDEO_TYPE,
  VIDEO_NODE_TYPE,
  VIMEO_VIDEO_TYPE,
} from '../../../data/models/LessonContent/LessonContentBase';
import {
  LessonContentItem,
  LessonContentList,
} from '../../../data/models/LessonContent/LessonContentList';

export const getSourceParent = (
  nodeId: string,
  lessonContent: LessonContentList,
): LessonContentItem | undefined => {
  const currentNode = lessonContent.contentList.items[nodeId];
  if (
    currentNode.data.ivNodeType === VIDEO_NODE_TYPE ||
    currentNode.data.ivNodeType === VIMEO_VIDEO_TYPE ||
    currentNode.data.ivNodeType === OVERLAY_VIDEO_TYPE ||
    currentNode.data.ivNodeType === OVERLAY_VIMEO_VIDEO_TYPE ||
    currentNode.data.ivNodeType === IMAGE_NODE_TYPE
  ) {
    return currentNode;
  } else if (
    !currentNode ||
    !currentNode.parentsIds ||
    !currentNode.parentsIds.length
  ) {
    return undefined;
  } else {
    return getSourceParent(currentNode.parentsIds[0], lessonContent);
  }
};

export const nodeIsAVideo = (type: number) => {
  return (
    type === VIDEO_NODE_TYPE ||
    type === VIMEO_VIDEO_TYPE ||
    type === OVERLAY_VIDEO_TYPE ||
    type === OVERLAY_VIMEO_VIDEO_TYPE
  );
};

export const getSiblingOptions = (
  currentNodeParentId: string,
  lessonContent: LessonContentList,
  currentNode?: LessonContentBase,
) => {
  let parentId = currentNodeParentId;
  if (currentNode && currentNode.internalId) {
    const currentNodeInList =
      lessonContent.contentList.items[currentNode.internalId];
    if (
      !currentNodeInList ||
      !currentNodeInList.parentsIds ||
      !currentNodeInList.parentsIds.length
    ) {
      return [];
    }
    parentId = currentNodeInList.parentsIds[0];
  }

  const siblings: LessonContentBase[] = [];
  Object.keys(lessonContent.contentList.items).forEach(key => {
    if (
      lessonContent.contentList.items[key].parentsIds.includes(parentId) &&
      lessonContent.contentList.items[key].data.internalId !==
        currentNode?.internalId
    ) {
      siblings.push(lessonContent.contentList.items[key].data);
    }
  });
  return siblings;
};

export const getParentIsChallenge = (
  parentId: string,
  lessonContent: LessonContentList,
) => {
  const parentNode = lessonContent.contentList.items[parentId];
  if (parentNode && parentNode.data.ivNodeType === CHALLENGE_BRANCH_QUESTION) {
    return true;
  }
  return false;
};

export const scaleImageOptionsForDisplay = (
  originalWidth: number,
  currentWidth: number,
  data: SvgImage,
) => {
  return {
    x: scaleNumberAccordingToWidth(data.x, originalWidth, currentWidth),
    y: scaleNumberAccordingToWidth(data.y, originalWidth, currentWidth),
    width: scaleNumberAccordingToWidth(data.width, originalWidth, currentWidth),
  } as SvgImage;
};

export const scaleTextOptionsForDisplay = (
  originalWidth: number,
  currentWidth: number,
  position: Point,
) => {
  return {
    x: scaleNumberAccordingToWidth(position.x, originalWidth, currentWidth),
    y: scaleNumberAccordingToWidth(position.y, originalWidth, currentWidth),
  } as Point;
};

export const scaleShapeOptionsForDisplay = (
  originalWidth: number,
  currentWidth: number,
  points: Point[],
) => {
  return points.map(point => ({
    x: scaleNumberAccordingToWidth(point.x, originalWidth, currentWidth),
    y: scaleNumberAccordingToWidth(point.y, originalWidth, currentWidth),
  }));
};

const scaleNumberAccordingToWidth = (
  x: number,
  originalWidth: number,
  currentWidth: number,
) => {
  return (x * currentWidth) / originalWidth;
};
