import React from 'react';
import {
  OVERLAY_ACTION_JUMP,
  OVERLAY_ACTION_NAVIGATION,
  OVERLAY_ACTION_SEEK,
  OVERLAY_ACTION_URL,
  Point,
  VideoOverlay,
} from '../../../../data/models/LessonContent/ContentFreeform';
import {LessonContentOverlayVideo} from '../../../../data/models/LessonContent/LessonContentBase';
import './styles.css';
import {getSecondsFromTimeText} from '../../../../utils/getTimeTextFromSeconds';

interface Props {
  width: number;
  onPause: () => void;
  seekTo: (second: number) => void;
  jumpToNode: (nodeId: string) => void;
  navigateTo: (lessonId: string) => void;
  overlayNode: LessonContentOverlayVideo;
  progress: number;
}

const FreeformOverlay = React.memo(
  ({
    width,
    onPause,
    progress,
    overlayNode,
    seekTo,
    jumpToNode,
    navigateTo,
  }: Props) => {
    const getObjectPath = (overlay: VideoOverlay) => {
      const points = overlay.overlayCoordinates;
      let path = 'M';
      if (points.length === 0) {
        return '';
      }

      points.forEach((point: Point) => {
        path +=
          scaleX(point.x, overlay.encodedAtWidth) +
          ' ' +
          scaleY(point.y, overlay.encodedAtWidth) +
          ' ';
      });
      path += 'z';
      return path;
    };

    const scaleX = (x: number, originalWidth: number) => {
      return (x * width) / originalWidth;
    };

    const scaleY = (y: number, originalWidth: number) => {
      return (y * width) / originalWidth;
    };

    const shouldShowOverlay = (overlay: VideoOverlay) => {
      if (
        overlay.overlayStartTime >= progress ||
        overlay.overlayEndTime <= progress
      ) {
        return false;
      } else {
        return true;
      }
    };

    const onOpenUrl = (url: string) => {
      onPause();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.open(url, '_blank').focus();
    };

    const handleOverlayAction = (overlay: VideoOverlay) => {
      if (overlay.actionType === OVERLAY_ACTION_URL && overlay.payload) {
        onOpenUrl(overlay.payload);
      } else if (
        overlay.actionType === OVERLAY_ACTION_SEEK &&
        overlay.payload
      ) {
        seekTo(getSecondsFromTimeText(overlay.payload));
      } else if (
        overlay.actionType === OVERLAY_ACTION_JUMP &&
        overlay.payload
      ) {
        jumpToNode(overlay.payload);
      } else if (
        overlay.actionType === OVERLAY_ACTION_NAVIGATION &&
        overlay.payload
      ) {
        navigateTo(overlay.payload);
      }
    };

    return (
      <div>
        <div
          style={{width, height: width / 1.77}}
          className="FreeformOverlayCanvas">
          <svg width={width} height={width / 1.77}>
            {overlayNode.overlays.map((overlay, index) => {
              if (shouldShowOverlay(overlay)) {
                return (
                  <path
                    fillOpacity={0.4}
                    key={index}
                    fill={overlay.overlayVisible ? '#3DA2FF' : 'transparent'}
                    d={getObjectPath(overlay)}
                    onPointerDown={() => handleOverlayAction(overlay)}
                  />
                );
              }
            })}
          </svg>
        </div>
      </div>
    );
  },
);

export default FreeformOverlay;
