import React, {useRef, useState} from 'react';
import {
  ACKNOWLEDGMENT_NODE,
  CHALLENGE_BRANCH_AI,
  CHALLENGE_BRANCH_HEADER,
  CHOICE_HEADER_NODE_TYPE,
  IMAGE_NODE_TYPE,
  LessonContentAcknowledgment,
  LessonContentBase,
  LessonContentImageSource,
  LessonContentMChoice,
  LessonContentMChoiceAnswer,
  LessonContentOverlayVideo,
  LessonContentVideo,
  MULTIPLE_CHOICE,
  OVERLAY_VIDEO_TYPE,
  OVERLAY_VIMEO_VIDEO_TYPE,
} from '../../../data/models/LessonContent/LessonContentBase';
import VideoPlayer from '../components/VideoPlayer';
import {LessonContentItem} from '../../../data/models/LessonContent/LessonContentList';
import StartingOverlay from '../overlays/StartingOverlay/StartingOverlay';
import EndingOverlay from '../overlays/EndingOverlay/EndingOverlay';
import MCHoiceOverlay from '../overlays/MChoiceOverlay/MChoiceOverlay';
import ChoiceOverlay from '../overlays/ChoiceOverlay/ChoiceOverlay';
import ChallengeBranchOverlay from '../overlays/ChallengeBranchOverlay/ChallengeBranchOverlay';
import ChallengeBranchAIOverlay from '../overlays/ChallengeBranchAIOverlay/ChallengeBranchAIOverlay';
import {GradeResult} from '../models';
import {
  ChallengeBranchAIMapped,
  ChoiceFreeformMapped,
  LessonContentChoiceAnswerMapped,
  LessonContentChoiceMapped,
} from '../../../data/models/LessonContent/LessonConentMapped';
import ResultsModal from '../components/ResultsModal';
import FreeformOverlay from '../overlays/FreeformOverlay/FreeformOverlay';
import './styles.css';
import ChoiceFreeformOverlay from '../overlays/ChoiceFreeformOverlay/ChoiceFreeformOverlay';
import {tapyblPlayerText} from '../tapyblPlayerText';
import AcknowledgmentOverlay from '../overlays/AcknowledgmentOverlay/AcknowledgmentOverlay';
import ImagePlayer from '../components/ImagePlayer';

interface Props {
  currentSourseNode?: LessonContentBase;
  started: boolean;
  ended: boolean;
  preview: boolean;
  onFinishedVideo: () => void;
  currentNode?: LessonContentItem;
  isGradable?: boolean;
  onStart: () => void;
  gradeResult?: GradeResult;
  onCourseHome: () => void;
  onContinue: () => void;
  nextLessonIsTheSame?: boolean;
  isLoading: boolean;
  passingScore: number;
  onSelectMChoice: (choices: LessonContentMChoiceAnswer[]) => void;
  onSelectChoice: (choice: LessonContentChoiceAnswerMapped) => void;
  onSelectChallenge: (choice: LessonContentChoiceAnswerMapped) => void;
  onSelectFreeformChoice: (choiceId: string) => void;
  onSelectChallengeAI: (
    answer: ChallengeBranchAIMapped,
    selectedAnswer: string,
  ) => void;
  onAcknowledge: (id: string) => void;
  fitHeight?: boolean;
  skipFinishButtons?: boolean;
  nextLessonId?: string;
  jumpToNode: (nodeId: string) => void;
  navigateTo: (lessonId: string) => void;
  showScormFinish?: boolean;
}

const TapyblPlayerUI = React.memo(
  ({
    currentSourseNode,
    started,
    ended,
    preview,
    onFinishedVideo,
    currentNode,
    isGradable,
    onStart,
    gradeResult,
    onContinue,
    onCourseHome,
    nextLessonIsTheSame,
    isLoading,
    passingScore,
    onSelectChallenge,
    onSelectChallengeAI,
    onSelectFreeformChoice,
    onSelectChoice,
    onSelectMChoice,
    fitHeight,
    skipFinishButtons,
    nextLessonId,
    jumpToNode,
    navigateTo,
    showScormFinish,
    onAcknowledge,
  }: Props) => {
    const [showResults, setShowResults] = useState(false);
    const [width, setWidth] = useState(0);
    const [progress, setProgres] = useState(0);
    const playerRef = useRef<any>(null);

    const onPause = () => {
      if (playerRef.current) {
        playerRef.current.pause();
      }
    };

    const seekTo = (second: number) => {
      if (playerRef.current) {
        playerRef.current.seekTo(second, 'seconds');
      }
    };

    const onJumpToNode = (nodeId: string) => {
      onPause();
      jumpToNode(nodeId);
    };

    const onNavigateTo = (lessonId: string) => {
      onPause();
      navigateTo(lessonId);
    };

    const getPlayerForSourceNode = () => {
      if (currentSourseNode?.ivNodeType === IMAGE_NODE_TYPE) {
        const currentImageNode = currentSourseNode as LessonContentImageSource;
        return (
          <ImagePlayer
            imageSource={currentImageNode}
            onFinished={onFinishedVideo}
            started={started}
            onWidthCalculated={setWidth}
            hideControls={
              currentNode?.data.ivNodeType === CHOICE_HEADER_NODE_TYPE ||
              currentNode?.data.ivNodeType === CHALLENGE_BRANCH_HEADER
            }
          />
        );
      } else {
        const currentVideoNode = currentSourseNode as LessonContentVideo;
        return (
          <VideoPlayer
            source={currentVideoNode?.contentUrl || ''}
            startTime={currentVideoNode.startInSeconds}
            endTime={currentVideoNode.endInSeconds}
            onFinished={onFinishedVideo}
            currentNode={currentNode}
            started={started}
            showControls={preview}
            fitHeight={fitHeight}
            onWidthCalculated={setWidth}
            reference={(ref: any) => {
              playerRef.current = ref;
            }}
            setProgres={setProgres}
            hideSmallControls={
              currentNode?.data.ivNodeType === CHOICE_HEADER_NODE_TYPE ||
              currentNode?.data.ivNodeType === CHALLENGE_BRANCH_HEADER
            }
          />
        );
      }
    };

    return currentSourseNode ? (
      <>
        <div
          style={{
            position: 'relative',
            overflow: 'hidden',
            borderRadius: '20px',
          }}>
          {getPlayerForSourceNode()}
          {!started && (
            <div className="PlayerBranchOverlay">
              <StartingOverlay
                gradable={isGradable || false}
                onStart={onStart}
                showResults={() => setShowResults(true)}
                gradeResult={gradeResult}
              />
            </div>
          )}
          {ended && (
            <div className="PlayerBranchOverlay">
              <EndingOverlay
                gradeResult={gradeResult}
                gradable={isGradable}
                onContinue={onContinue}
                onCourseHome={onCourseHome}
                preview={preview}
                isLoading={isLoading}
                nextLessonIsTheSame={nextLessonIsTheSame}
                showResults={() => setShowResults(true)}
                skipFinishButtons={skipFinishButtons}
                nextLessonId={nextLessonId}
                showScormFinish={showScormFinish}
              />
            </div>
          )}
          {currentNode?.data.ivNodeType === MULTIPLE_CHOICE && (
            <div className="PlayerBranchOverlay">
              <MCHoiceOverlay
                onSelectChoice={onSelectMChoice}
                mChoice={currentNode.data as LessonContentMChoice}
                passingScore={passingScore}
              />
            </div>
          )}
          {(currentNode?.data.ivNodeType === OVERLAY_VIDEO_TYPE ||
            currentNode?.data.ivNodeType === OVERLAY_VIMEO_VIDEO_TYPE) &&
            started && (
              <FreeformOverlay
                overlayNode={currentNode.data as LessonContentOverlayVideo}
                progress={progress}
                width={width}
                onPause={onPause}
                navigateTo={onNavigateTo}
                seekTo={seekTo}
                jumpToNode={onJumpToNode}
              />
            )}
          {currentNode?.data.ivNodeType === CHOICE_HEADER_NODE_TYPE && (
            <>
              {(currentNode.data as LessonContentChoiceMapped).isFreeform ? (
                <ChoiceFreeformOverlay
                  width={width}
                  overlayNode={currentNode.data as ChoiceFreeformMapped}
                  onSelectFreeformChoice={onSelectFreeformChoice}
                  passingScore={passingScore}
                />
              ) : (
                <div className="PlayerBranchOverlay">
                  <ChoiceOverlay
                    onSelectChoice={onSelectChoice}
                    choice={currentNode.data as LessonContentChoiceMapped}
                  />
                </div>
              )}
            </>
          )}
          {currentNode?.data.ivNodeType === CHALLENGE_BRANCH_HEADER && (
            <>
              {(currentNode.data as LessonContentChoiceMapped).isFreeform ? (
                <ChoiceFreeformOverlay
                  width={width}
                  overlayNode={currentNode.data as ChoiceFreeformMapped}
                  onSelectFreeformChoice={onSelectFreeformChoice}
                  passingScore={passingScore}
                />
              ) : (
                <div className="PlayerBranchOverlay">
                  <ChallengeBranchOverlay
                    onSelectChoice={onSelectChallenge}
                    choice={currentNode.data as LessonContentChoiceMapped}
                    passingScore={passingScore}
                  />
                </div>
              )}
            </>
          )}
          {currentNode?.data.ivNodeType === CHALLENGE_BRANCH_AI && (
            <ChallengeBranchAIOverlay
              passingScore={passingScore}
              challengeBranch={currentNode.data as ChallengeBranchAIMapped}
              onSelectChallengeAI={onSelectChallengeAI}
            />
          )}
          {currentNode?.data.ivNodeType === ACKNOWLEDGMENT_NODE && (
            <div className="PlayerBranchOverlay">
              <AcknowledgmentOverlay
                acknowledgment={currentNode.data as LessonContentAcknowledgment}
                onAcknowledge={onAcknowledge}
              />
            </div>
          )}
        </div>
        {showResults && gradeResult && (
          <ResultsModal
            results={gradeResult}
            onCancel={() => setShowResults(false)}
            height={width / 1.77}
          />
        )}
      </>
    ) : (
      <div className="LessonIsEmptyContainer">
        <h3>{tapyblPlayerText.lessonIsEmpty}</h3>
      </div>
    );
  },
);

export default TapyblPlayerUI;
