import {makeAutoObservable} from 'mobx';
import CoursesRepository from '../../../../data/repositories/CoursesRepository';
import {toast} from 'react-toastify';
import {getErrorFromCode} from '../../../../data/errorCodes';
import {toastConfig} from '../../../../uiToolkit/Toast/toastConfig';
import {coursePreviewText} from '../coursePreviewText';
import LoadingModule from '../../../../modules/LoadingModule/LoadingModule';

class CoursePreviewStore {
  private courseRepository = new CoursesRepository();
  private loadingModule = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async getCoursePresentation(organizationId: string, courseId: string) {
    this.loadingModule.startLoading();
    const result = await this.courseRepository.getCoursePreview(
      organizationId,
      courseId,
    );
    this.loadingModule.endLoading();
    if (result.success && result.data) {
      return result.data;
    }
  }

  public async publishCourse(organizationId: string, courseId: string) {
    this.loadingModule.startLoading();
    const result = await this.courseRepository.publishCourse(
      organizationId,
      courseId,
    );
    this.loadingModule.endLoading();
    if (result.success) {
      toast.success(coursePreviewText.publishSuccessful, toastConfig);
    }
    return result.success;
  }

  public async republishCourse(organizationId: string, courseId: string) {
    this.loadingModule.startLoading();
    const result = await this.courseRepository.republishCourse(
      organizationId,
      courseId,
    );
    this.loadingModule.endLoading();
    if (!result.success && result.errors && result.errors.length) {
      toast.error(getErrorFromCode(result.errors[0]), toastConfig);
    }
    if (result.success) {
      toast.success(coursePreviewText.republishSuccessful, toastConfig);
    }
    return result.success;
  }
}

export default CoursePreviewStore;
