import React, {useRef} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {selectSubscriptionText} from '../selectSubscriptionText';
import './styles.css';
import {subscriptionPlans} from '../subscriptionPlans';
import FeaturesTable from '../components/FeaturesTable';
import SubscriptionPlan from '../../../../uiToolkit/SubscriptionPlanCard';

interface Props {
  onSelect: (tier?: number) => void;
}

const SelectSubscriptionUI = React.memo(({onSelect}: Props) => {
  const elToScroll = useRef<any>(null);

  const scrollToList = () => {
    if (elToScroll && elToScroll.current) {
      elToScroll.current.scrollIntoView();
    }
  };

  return (
    <div className="SelectSubscriptionContainer">
      <Container fluid>
        <Row>
          <Col xl={{span: 8, offset: 2}} xs={12}>
            <h2 className="SelectTitle">
              {selectSubscriptionText.selectTitle}
            </h2>
            <p className="SelectSubtitle">{selectSubscriptionText.subtitle}</p>
            <div className="SubscriptionPlansContainer">
              {subscriptionPlans.map((item, index) => (
                <SubscriptionPlan
                  {...item}
                  onClick={onSelect}
                  key={index}
                  onShowFullList={scrollToList}
                />
              ))}
            </div>
            <p className="UnderTierInfo">
              {selectSubscriptionText.underTierInfo}
            </p>
          </Col>
        </Row>
        <Row>
          <Col xl={{span: 8, offset: 2}} xs={12}>
            <h3 className="FullListTitle" ref={elToScroll}>
              {selectSubscriptionText.fullList}
            </h3>
            <FeaturesTable />
            <p className="Footnote">{selectSubscriptionText.tableFootnote}</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
});

export default SelectSubscriptionUI;
