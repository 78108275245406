import React from 'react';
import NavBar from '../../../../uiToolkit/NavBar';
import {
  CoursePresentationModel,
  CoursePresentationOrganization,
  FolderPresentationModel,
} from '../../../../data/models/Course/CoursePresentation';
import Breadcrumbs from '../../../../uiToolkit/Breadcrumbs';
import {coursePreviewText} from '../coursePreviewText';
import './styles.css';
import {Accordion, Col, Container, Row} from 'react-bootstrap';
import OrganizationBadge from '../../../../uiToolkit/OrganizationBadge';
import IconButton from '../../../../uiToolkit/Buttons/IconButton';
import {
  courseActionsLessonsIc,
  dividerWhiteIc,
  lessonListPlayButtonIc,
} from '../../../../assets/images';
import Tooltip from '../../../../uiToolkit/Tooltip';
import Button from '../../../../uiToolkit/Buttons/Button';
import {formatDateTime} from '../../../../utils/notificationTimeTextConvertor';
import {
  COURSE_PUBLISHED_STATUS,
  COURSE_PUBLISHING_STATUS,
} from '../../../../data/models/Course/Course';

interface Props {
  course?: CoursePresentationModel;
  organization?: CoursePresentationOrganization;
  courseLink: string;
  onEditFolders: () => void;
  onLessonClick: (lessonId: string) => void;
  onPublishCourse: () => void;
  isLoading: boolean;
  hasPublishPermission: boolean;
}

const CoursePreviewUI = React.memo(
  ({
    course,
    organization,
    courseLink,
    onEditFolders,
    onLessonClick,
    onPublishCourse,
    isLoading,
    hasPublishPermission,
  }: Props) => {
    const getBreadcrumbs = () => {
      return [
        {title: coursePreviewText.courses, link: courseLink},
        {title: course?.title || ''},
      ];
    };

    const folderIsValid = (folder: FolderPresentationModel) => {
      return folder.lessons && folder.lessons.length;
    };

    const calculateLessonsAmount = () => {
      let lessonsAmount = 0;
      course?.folders.forEach(item => {
        lessonsAmount += item.lessons.length;
      });
      return lessonsAmount;
    };

    return (
      <div>
        <NavBar />
        <Container>
          <Row>
            <Col lg={9} xs={12}>
              <div className="CoursePreviewContainer">
                <Breadcrumbs steps={getBreadcrumbs()} />
                <h2 className="Title">{course?.title}</h2>
                <OrganizationBadge
                  avatar={organization?.avatarUrl}
                  name={organization?.name}
                />
                <p className="Description">{course?.description}</p>
                <div className="d-flex flex-row align-items-center">
                  <p className="FoldersHeading">
                    {coursePreviewText.inThisCourse}
                  </p>
                  <IconButton
                    onClick={onEditFolders}
                    icon="icon-typeEdit-with-square"
                  />
                </div>
                {course?.folders
                  .sort((a, b) => (a.order > b.order ? 1 : -1))
                  .map((item, index) => (
                    <Accordion
                      defaultActiveKey={
                        folderIsValid(item) ? index.toString() : ''
                      }
                      key={index.toString()}>
                      <Accordion.Item eventKey={index.toString()}>
                        <Accordion.Header>
                          <span className="accordion-title">{item.name}</span>
                          <img src={dividerWhiteIc} />
                          {item.lessons?.length} {coursePreviewText.lessons}
                          {!folderIsValid(item) && (
                            <Tooltip
                              tooltipText={coursePreviewText.invalidFolder}>
                              <span className="icon-typeWarning FolderWarning"></span>
                            </Tooltip>
                          )}
                        </Accordion.Header>
                        <Accordion.Body>
                          {(item.lessons || [])
                            .sort((a, b) => (a.order > b.order ? 1 : -1))
                            .map(lesson => (
                              <div
                                key={lesson.id}
                                onClick={() => {
                                  onLessonClick(lesson.id);
                                }}
                                className={'LessonContainer'}>
                                <div className="d-flex flex-row w-100">
                                  <img src={lessonListPlayButtonIc} />
                                  <p>{lesson.title}</p>
                                </div>
                              </div>
                            ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ))}
              </div>
            </Col>
            <Col xs={12} lg={3} className="mt-4">
              <div className="CoursePublishCard">
                <img className="Poster" src={course?.avatarUrl} alt="" />
                <p className="Title">{coursePreviewText.coursePreview}</p>
                <div className="CourseActionInfo">
                  <div>
                    <img src={courseActionsLessonsIc} />
                    <span className="Info">
                      {calculateLessonsAmount()}{' '}
                      {coursePreviewText.lessonsAmount(
                        calculateLessonsAmount(),
                      )}
                    </span>
                  </div>
                </div>
                {hasPublishPermission ? (
                  <Button
                    title={
                      course?.publishStatus === COURSE_PUBLISHED_STATUS
                        ? coursePreviewText.republishCourse
                        : course?.publishStatus === COURSE_PUBLISHING_STATUS
                        ? coursePreviewText.publishingCourse
                        : coursePreviewText.publishCourse
                    }
                    onClick={onPublishCourse}
                    className="PublishButton"
                    uiType="action"
                    isLoading={isLoading}
                    disabled={
                      course?.publishStatus === COURSE_PUBLISHING_STATUS
                    }
                  />
                ) : (
                  <span className="Info ContactPrompt">
                    {coursePreviewText.contactAdmin}
                  </span>
                )}
                {course?.datePublished && (
                  <p className="PublishDate">
                    {coursePreviewText.datePublished}{' '}
                    {formatDateTime(course?.datePublished)}
                  </p>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  },
);

export default CoursePreviewUI;
