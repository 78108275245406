import {makeAutoObservable} from 'mobx';
import UserRepository from '../../../../data/repositories/UserRepository';
import LoadingModule from '../../../../modules/LoadingModule/LoadingModule';

class SecurityUserProfileStore {
  private userRepository = new UserRepository();
  private loadingModule = new LoadingModule();

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading() {
    return this.loadingModule.isLoading;
  }

  public async editUserPassword(
    password: string,
    newPassword: string,
    confirmNewPassword: string,
  ) {
    this.loadingModule.startLoading();
    const result = await this.userRepository.changeUserPassword(
      password,
      newPassword,
      confirmNewPassword,
    );
    this.loadingModule.endLoading();
    return result.success;
  }
}

export default SecurityUserProfileStore;
