import React, {useRef, useEffect, useState} from 'react';
import {Point} from '../../../../data/models/LessonContent/ContentFreeform';
import '../styles.css';
import {wrapLabel} from '../../../../utils/wrapText';

interface Props {
  position: Point;
  text: string;
  fontSize: number;
  color: string;
  updateTextOptions?: (position: Point) => void;
  onClick?: () => void;
}

export const DEFAULT_SVG_FONT_SIZE = 16;
const PADDING = 10; // Define el padding que deseas
const BORDER_RADIUS = 10; // Define el radio de los bordes redondeados

const EditorSvgText = React.memo(
  ({position, text, fontSize, updateTextOptions, onClick}: Props) => {
    const [textPosition, setTextPosition] = useState<Point>({x: 0, y: 0});
    const [resizeData, setResizeData] = useState({
      active: false,
      offset: {x: 0, y: 0},
    });
    const [textDimensions, setTextDimensions] = useState({width: 0, height: 0});
    const textRef = useRef<SVGTextElement | null>(null);

    useEffect(() => {
      setTextPosition({...position});
    }, [position]);

    useEffect(() => {
      if (textRef.current) {
        const bbox = textRef.current.getBBox();
        setTextDimensions({
          width: bbox.width,
          height: bbox.height,
        });
      }
    }, [text, fontSize]);

    const handlePointerDown = (e: any) => {
      if (!updateTextOptions) return;
      const el = e.target;
      const bbox = e.target.getBoundingClientRect();
      const x = e.clientX - bbox.left;
      const y = e.clientY - bbox.top;
      el.setPointerCapture(e.pointerId);
      setResizeData({
        ...resizeData,
        active: true,
        offset: {
          x,
          y,
        },
      });
    };

    const handleItemClick = (e: any) => {
      if (onClick) {
        onClick();
        e.stopPropagation();
      }
    };

    const handlePointerMove = (e: any) => {
      const bbox = e.target.getBoundingClientRect();
      const x = e.clientX - bbox.left;
      const y = e.clientY - bbox.top;
      if (resizeData.active) {
        setTextPosition({
          x: textPosition.x - (resizeData.offset.x - x),
          y: textPosition.y - (resizeData.offset.y - y),
        });
      }
    };

    const handlePointerUp = () => {
      setResizeData({
        ...resizeData,
        active: false,
      });
      if (updateTextOptions) {
        updateTextOptions(textPosition);
      }
    };

    return (
      <g
        transform={`translate(${textPosition.x}, ${textPosition.y})`}
        onPointerDown={handleItemClick}>
        <rect
          x={-textDimensions.width / 2 - PADDING}
          y={fontSize / -1.7 - PADDING}
          width={textDimensions.width + 2 * PADDING}
          height={textDimensions.height + 2 * PADDING}
          fill="#056260"
          rx={BORDER_RADIUS}
          ry={BORDER_RADIUS}
          cursor={onClick ? 'pointer' : ''}
        />
        <text
          ref={textRef}
          x={0}
          y={0}
          cursor={updateTextOptions ? 'move' : onClick ? 'pointer' : ''}
          className="SvgText"
          fill={'#ffffff'}
          fontSize={fontSize || DEFAULT_SVG_FONT_SIZE}
          dominantBaseline="middle"
          textAnchor="middle"
          onPointerDown={handlePointerDown}
          onPointerUp={handlePointerUp}
          onPointerMove={handlePointerMove}>
          {wrapLabel(text, 200, fontSize || DEFAULT_SVG_FONT_SIZE).map(
            (t, i) => (
              <tspan
                fontSize={fontSize || DEFAULT_SVG_FONT_SIZE}
                key={i}
                x={0}
                dy={i === 0 ? 0 : fontSize || DEFAULT_SVG_FONT_SIZE}>
                {t}
              </tspan>
            ),
          )}
        </text>
      </g>
    );
  },
);

export default EditorSvgText;
