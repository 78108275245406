import React, {useState} from 'react';
import {inject, observer} from 'mobx-react';
import LearnersStore from '../../store/LearnersStore';
import CreateLearnersUI from '../view/CreateLearnersUI';
import {useNavigate, useParams} from 'react-router-dom';
import NavigationStore from '../../../../navigation/store/NavigationStore';

interface Props {
  learnersStore?: LearnersStore;
  navigationStore?: NavigationStore;
}

const CreateLearnersContainer = inject(
  'learnersStore',
  'navigationStore',
)(
  observer(({learnersStore, navigationStore}: Props) => {
    const [learners, setLearners] = useState<string[]>([]);
    const [failedInvitations, setFailedInvitations] = useState<any>({});
    const {organizationId} = useParams();
    const navigate = useNavigate();

    const onSubmit = async (learners: string[]) => {
      if (organizationId) {
        setFailedInvitations({});
        await learnersStore!
          .addLearners(organizationId, learners)
          .then(result => {
            if (
              result.data?.result &&
              Object.keys(result.data?.result.failedReasons).length
            ) {
              setFailedInvitations(result.data?.result.failedReasons);
            } else if (result.success) {
              navigationStore!.getOrganizationUsersCount(organizationId);
              setLearners([]);
              navigate(-1);
            }
          });
      }
    };

    const onCancel = () => {
      navigate(`/manage/${organizationId}/learners/general`, {replace: true});
    };

    return (
      <CreateLearnersUI
        onSubmit={onSubmit}
        onCancel={onCancel}
        learners={learners}
        setLearners={setLearners}
        isLoading={learnersStore!.isLoading}
        failedInvitations={failedInvitations}
      />
    );
  }),
);

export default CreateLearnersContainer;
