import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';

const english = {
  successMessage: 'Instructor Assigned Successfully',
  errorMessage: 'Failed to Assign Instructor',
  currentInstructor: 'Current Instructor',
  eligibleInstructors: 'Eligible Instructors',
  assignInstructor: 'Assign Instructor',
  notPublished: 'To add instructors you need to publish this course',
};

const welsh = {
  successMessage: "Hyfforddwr Wedi'i Dyrannu'n Llwyddiannus",
  errorMessage: "Methwyd â Rhoi'r Hyfforddwr",
  currentInstructor: 'Hyfforddwr Presennol',
  eligibleInstructors: 'Hyfforddwyr Cymwys',
  assignInstructor: 'Dyrannu Hyfforddwr',
  notPublished: "I ychwanegu hyfforddwyr, mae angen i chi gyhoeddi'r cwrs hwn",
};

export const courseInstructorText: any = getLocalizationBasedOnLang(
  english,
  welsh,
);
