import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {subscriptionFirstPaymentText} from '../subscriptionFirstPaymentText';
import Button from '../../../../uiToolkit/Buttons/Button';
import './styles.css';
import {getTierSummaryConfig} from '../tierSummaryConfig';
import Lottie from 'lottie-react';
import {loadingLottie} from '../../../../assets/lottie';

interface Props {
  onChoosePlan: () => void;
  tier?: number;
  onPay: () => void;
  isLoading: boolean;
}

const SubscriptionFirstPaymentUI = React.memo(
  ({onChoosePlan, tier, onPay, isLoading}: Props) => {
    if (isLoading) {
      return (
        <div className="LoaderContainer">
          <Lottie
            animationData={loadingLottie}
            loop={true}
            style={{width: '48px', height: '48px'}}
          />
        </div>
      );
    }
    return (
      <Container>
        <Row>
          <Col xl={{span: 8, offset: 2}} xs={12}>
            <div className="SubFirstPaymentContainer">
              <Button
                uiType="text"
                onClick={onChoosePlan}
                title={subscriptionFirstPaymentText.goBack}
                icon="icon-typeGo-Back"
                className="BackButton"
              />
              <h2 className="Title">{subscriptionFirstPaymentText.title}</h2>
              <h4 className="Subtitle">
                {subscriptionFirstPaymentText.subtitle}
              </h4>
              <p className="InfoText">{subscriptionFirstPaymentText.info1}</p>

              <ul>
                <li className="InfoText">
                  {subscriptionFirstPaymentText.point1(
                    getTierSummaryConfig(tier)?.price || '',
                  )}
                </li>
                <li className="InfoText">
                  {subscriptionFirstPaymentText.point2}
                </li>
                <li className="InfoText">
                  {subscriptionFirstPaymentText.point3}{' '}
                  <a
                    href="https://tapybl.com/full-terms-and-conditions/"
                    target="_blank"
                    rel="noreferrer">
                    {subscriptionFirstPaymentText.terms}
                  </a>
                </li>
              </ul>

              <h6 className="SummaryText">
                {subscriptionFirstPaymentText.summary}
              </h6>
              <div className="SummaryContainer">
                <div>
                  <p className="SummarySectionTitle">
                    {subscriptionFirstPaymentText.selectedTier}
                  </p>
                  <p className="SelecterTier">
                    {getTierSummaryConfig(tier)?.title}
                  </p>
                </div>
                <div>
                  <p className="SummarySectionTitle">
                    {subscriptionFirstPaymentText.planDescription}
                  </p>
                  <div className="d-flex flex-row">
                    <ul className="Features">
                      {getTierSummaryConfig(tier)?.features1.map(feature => (
                        <li key={feature}>
                          <span className="icon-typeCheck"></span>
                          {feature}
                        </li>
                      ))}
                    </ul>
                    <ul className="Features">
                      {getTierSummaryConfig(tier)?.features2.map(feature => (
                        <li key={feature}>
                          <span className="icon-typeCheck"></span>
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div>
                  <p className="SummarySectionTitle">
                    {subscriptionFirstPaymentText.price}
                  </p>
                  <p className="SelecterTier">
                    {getTierSummaryConfig(tier)?.price}
                  </p>
                </div>
              </div>
              <div className="Footer">
                <Button
                  title={subscriptionFirstPaymentText.proceed}
                  onClick={onPay}
                  className="PaySubButton"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  },
);

export default SubscriptionFirstPaymentUI;
