import React, {useRef, useState} from 'react';
import './styles.css';
import {appText} from '../../../assets/text/appText';
import {deleteIcon} from '../../../assets/images';

interface Props {
  onChange: (file?: File) => void;
  placeholder: string;
  heading?: string;
  image?: File;
  className?: string;
  existingImage?: string;
  existingImageName?: string;
  isMandatory?: boolean;
}

const ImagePickerInput = React.memo(
  ({
    onChange,
    placeholder,
    heading,
    image,
    className,
    existingImage,
    existingImageName,
    isMandatory,
  }: Props) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [fileKey, setFileKey] = useState(Math.random() % 1000);

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
        onChange(file);
        setFileKey(Math.random() % 1000);
      }
    };

    const clearImage = () => {
      onChange();
    };

    const onImageClick = () => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    };

    return (
      <div className={className}>
        <p className="ImagePickerTitle">
          {heading}
          {isMandatory ? <span className="MandatoryIndicator">*</span> : ''}
        </p>
        <div className="ImagePickerContainer">
          {image ? (
            <div className="ImageContainer">
              <img
                src={URL.createObjectURL(image)}
                className="AttachFileImage"
                onClick={onImageClick}
              />
              <button className="ClearButton" onClick={clearImage}>
                <img src={deleteIcon} alt="delete" />
              </button>
            </div>
          ) : (
            existingImage && (
              <div className="ImageContainer">
                <img
                  src={existingImage}
                  className="AttachFileImage"
                  onClick={onImageClick}
                />
              </div>
            )
          )}
          <div className="ImagePickerInput">
            {image ? (
              <span className="Title">{image.name}</span>
            ) : existingImageName ? (
              <span className="Title">Workspace Image</span>
            ) : (
              <span>{placeholder}</span>
            )}
            <label className="AttachFileButton">
              {appText.browse}
              <input
                ref={fileInputRef}
                type="file"
                key={fileKey}
                onChange={onFileChange}
                accept="image/*"
                style={{display: 'none'}}
              />
            </label>
          </div>
        </div>
      </div>
    );
  },
);

export default ImagePickerInput;
