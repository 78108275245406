import React from 'react';
import {Col} from 'react-bootstrap';
import UploadPPTButton from '../../../uiToolkit/Inputs/UploadPPTButton';
import {generateAIVideosText} from '../generateAIVideosText';

interface Props {
  onFileSelected: (file: File) => void;
}

const UploadFileStep = React.memo(({onFileSelected}: Props) => {
  return (
    <Col lg={6} xs={12} className="formBox">
      <h5>{generateAIVideosText.uploadPPTText}</h5>
      <h6>{generateAIVideosText.uploadPPTSubtitle}</h6>
      <UploadPPTButton onPPTSelected={onFileSelected} title={''} isMandatory />
    </Col>
  );
});

export default UploadFileStep;
