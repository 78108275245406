import {getLocalizationBasedOnLang} from '../../../data/models/User/UserInfo';
import {
  SUBSCRIPTION_BUISNESS_BASIC,
  SUBSCRIPTION_BUISNESS_BASIC_TRIAL,
  SUBSCRIPTION_CREATOR,
  SUBSCRIPTION_CREATOR_TRIAL,
  SUBSCRIPTION_ENTERPRISE,
} from '../../Subscription/config';

const CREATOR_CONF = {
  id: SUBSCRIPTION_CREATOR,
  title: getLocalizationBasedOnLang('Creator', 'Creawdwr'),
  price: '€13.49',
  amountOfUsers: getLocalizationBasedOnLang(
    'Up to 19 Users',
    'Hyd at 19 Defnyddwyr',
  ),
  features: [
    getLocalizationBasedOnLang('1 Workspace', '1 Gweithle'),
    getLocalizationBasedOnLang(
      'Advanced Interactive Features',
      'Nodweddion Rhyngweithiol Uwch',
    ),
    getLocalizationBasedOnLang('Unlimited Courses', 'Cyrsiau Di-ben-draw'),
    getLocalizationBasedOnLang(
      '200,000 AI Credits included',
      '200,000 Credyd AI wedi’u cynnwys',
    ),
    getLocalizationBasedOnLang('5GB Storage', '5GB Storio'),
    getLocalizationBasedOnLang(
      'Limited to 1 Course Creator per workspace',
      'Cyfyngedig i 1 Creawdwr Cwrs y Gweithle.',
    ),
  ],
  buttonText: getLocalizationBasedOnLang('Select plan', 'Dewiswch Gynllun'),
  green: false,
  showDetails: true,
  startsAt: getLocalizationBasedOnLang(
    '*Starts at €899',
    '*Dechreuodd am €899',
  ),
  usersPerMonth: true,
  firstFeatureBold: false,
};

const BUISNESS_CONF = {
  id: SUBSCRIPTION_BUISNESS_BASIC,
  title: getLocalizationBasedOnLang('Business Basic', 'Busnes Sylfaenol'),
  price: '€11.99',
  amountOfUsers: getLocalizationBasedOnLang(
    'Up to 250 Users',
    'Hyd at 250 defnyddwyr',
  ),
  features: [
    getLocalizationBasedOnLang(
      'Everything in the Creator Plan, plus',
      'Popeth yn y Cynllun Creawdwr, yn ogystal â',
    ),
    getLocalizationBasedOnLang(
      '400,000 AI Credits included',
      '400,000 Credyd AI wedi’u cynnwys',
    ),
    getLocalizationBasedOnLang(
      'LTI Support for seamless integration with other platforms',
      'Chymorth LTI ar gyfer integreiddiad di-dor â phlatfformau eraill',
    ),
    getLocalizationBasedOnLang(
      'No limit on the number of Course Creators per workspace',
      'Dim terfyn ar nifer y Creuwyr Cyrsiau y Gweithle',
    ),
    getLocalizationBasedOnLang('50GB Storage', '50 GB Storio'),
    getLocalizationBasedOnLang(
      'White label/Customization',
      'Label Gwyn/ Addasu',
    ),
  ],
  buttonText: getLocalizationBasedOnLang('Select plan', 'Dewiswch Gynllun'),
  green: true,
  showDetails: true,
  startsAt: getLocalizationBasedOnLang(
    '*Starts at €3,778',
    '*Dechreuodd am €3,778',
  ),
  usersPerMonth: true,
  firstFeatureBold: true,
};

const ENTERPRISE_CONF = {
  title: getLocalizationBasedOnLang('Enterprise', 'Menter'),
  price: getLocalizationBasedOnLang('On Request', 'Ar Gais'),
  amountOfUsers: getLocalizationBasedOnLang(
    'Unlimited Users',
    'Defnyddwyr Di-ben-draw',
  ),
  features: [
    getLocalizationBasedOnLang(
      'Everything in the Business Plan, plus',
      'Popeth yn y Cynllun Busnes, yn ogystal â',
    ),
    getLocalizationBasedOnLang(
      '3,000,000 AI Credits included',
      '3,000,000 Credyd AI wedi’u cynnwys',
    ),
    getLocalizationBasedOnLang('100GB Storage', '100 GB Storio'),
    getLocalizationBasedOnLang(
      'Ultra High Definition Content Delivery',
      'Cyflenwi Cynnwys Ucheldro Uchel',
    ),
    getLocalizationBasedOnLang(
      'Full SCORM Support with Player Integration',
      'Cymorth llawn SCORM gyda Integreiddiad Chwaraewr',
    ),
    getLocalizationBasedOnLang(
      'Custom Data Storage & Integrations',
      'Storio Data Custom a Chydweithrediadau',
    ),
    getLocalizationBasedOnLang(
      'Personalized Account & Onboarding Support',
      'Cymorth Cyfrif Personol a Chymorth Cyflwyno',
    ),
  ],
  buttonText: getLocalizationBasedOnLang('Get Quote', 'Cafwch Dyfynbris'),
  green: false,
  showDetails: false,
  startsAt: null,
  usersPerMonth: false,
  firstFeatureBold: true,
  buttonSecondary: true,
};

export const settingsSubscriptionPlans = (currentPlanId: number) => {
  const result = [];
  if (
    currentPlanId === SUBSCRIPTION_CREATOR_TRIAL ||
    currentPlanId === SUBSCRIPTION_CREATOR
  ) {
    result.push(CREATOR_CONF);
    result.push(BUISNESS_CONF);
    result.push(ENTERPRISE_CONF);
  }
  if (
    currentPlanId === SUBSCRIPTION_BUISNESS_BASIC ||
    currentPlanId === SUBSCRIPTION_BUISNESS_BASIC_TRIAL
  ) {
    result.push(BUISNESS_CONF);
    result.push(ENTERPRISE_CONF);
  }
  if (currentPlanId > SUBSCRIPTION_BUISNESS_BASIC) {
    result.push(ENTERPRISE_CONF);
  }
  if (currentPlanId === SUBSCRIPTION_ENTERPRISE) {
    return [];
  }
  return result;
};
