import React from 'react';
import {inject, observer} from 'mobx-react';
import {LessonContentFreeformImageChoice} from '../../../../../data/models/LessonContent/LessonContentBase';
import FreeformImageOptionsUI from '../view/FreeformImageOptionsUI';
import AddFreeformChoiceStore from '../../../../../modules/Editor/AddFreeformChoiceOption/store/AddFreeformChoiceStore';
import BaseOptionsContainer, {
  BaseContainerForwardProps,
  BaseContainerProps,
} from '../../BaseOptionsContainer';
import {lessonEditorText} from '../../../lessonEditorText';

interface Props extends BaseContainerForwardProps {
  addFreeformChoiceStore?: AddFreeformChoiceStore;
  entityToEdit: LessonContentFreeformImageChoice;
}

const FreeformImageOptionsContainer = inject('addFreeformChoiceStore')(
  observer(
    ({
      entityToEdit,
      isPublished,
      addFreeformChoiceStore,
      onEdit,
      onDelete,
      isLoading,
      onChangesMade,
    }: Props) => {
      const onEditImage = (title: string, isCorrect: boolean | null) => {
        onEdit({
          ...entityToEdit,
          title,
          isCorrect,
        } as LessonContentFreeformImageChoice);
      };

      const openEditOverlay = () => {
        if (entityToEdit) {
          addFreeformChoiceStore!.showWizardForEdit(
            entityToEdit,
            onChangesMade,
          );
        }
      };

      return entityToEdit ? (
        <>
          <FreeformImageOptionsUI
            entityToEdit={entityToEdit}
            openEditOverlay={openEditOverlay}
            isLoading={isLoading}
            onSave={onEditImage}
            onDelete={onDelete}
            isPublished={isPublished}
          />
        </>
      ) : null;
    },
  ),
);

export default React.memo((props: BaseContainerProps) => (
  <BaseOptionsContainer
    {...props}
    deleteMessage={lessonEditorText.areYouSureDeleteChoiceImageOption}>
    {forwardProps => (
      <FreeformImageOptionsContainer
        {...forwardProps}
        entityToEdit={
          forwardProps.entityToEdit as LessonContentFreeformImageChoice
        }
      />
    )}
  </BaseOptionsContainer>
));
